import API from '../api/api';

export class PlansService {
  async getAnnualPlans(): Promise<any> {
    const data = await API.get('/annual_plans/');

    return data;
  }
  async getAnnualPlan(id: string): Promise<any> {
    const data = await API.get(`/annual_plans/${id}/`);

    return data;
  }

  async getWeekPlan(id: string): Promise<any> {
    const data = await API.get(`/week_plan/${id}/`);

    return data;
  }

  async filterWeekPlan(payload: any): Promise<any> {
    const data = await API.get(`/week_plan/${payload.id}`, {
      params: {
        end_date: payload.end_week,
        start_date: payload.start_week,
      },
    });

    return data;
  }

  async getSessionValues(payload: any): Promise<any> {
    const data = await API.get(`/session_values/${payload.id}`, {
      params: {
        end_date: payload.end_week,
        start_date: payload.start_week,
      },
    });
    return data;
  }

  async getCompetition(id: string): Promise<any> {
    const data = await API.get(`/competition/${id}/`);

    return data;
  }

  async postCompetition(payload: any): Promise<any> {
    const data = await API.post('/add_competition/', payload);

    return data;
  }

  async putCompetition(payload: any, id: string): Promise<any> {
    const data = await API.put(`/destroy_update_competition/${id}/`, payload);

    return data;
  }

  async deleteCompetition(id: string): Promise<any> {
    const data = await API.delete(`/destroy_update_competition/${id}`);

    return data;
  }

  async postCompetitionReport(payload: any): Promise<any> {
    const data = await API.post('/add_competition_report/', payload);

    return data;
  }

  async postTrainig(payload: any): Promise<any> {
    const data = await API.post('/add_training/', payload);

    return data;
  }

  async putTraining(payload: any, id: string): Promise<any> {
    const data = await API.put(`/destroy_update_training/${id}/`, payload);

    return data;
  }

  async deleteTraining(id: string): Promise<any> {
    const data = await API.delete(`/destroy_update_training/${id}`);

    return data;
  }

  async postTrainingReport(payload: any): Promise<any> {
    const data = await API.post('/add_training_report/', payload);

    return data;
  }

  async getTemplates(): Promise<any> {
    const data = await API.get('/training_template/');

    return data;
  }

  async saveTemplate(payload: any): Promise<any> {
    const data = await API.post('/training_template/', payload);

    return data;
  }

  async applyTemplate(payload: any): Promise<any> {
    const data = await API.post('/populate_trainings_from_template/', payload);

    return data;
  }


  async deleteTemplate(id: any): Promise<any> {
    const data = await API.delete(`/delete_training_template/${id}`);

    return data;
  }


}

export const plansService = new PlansService();
