import React, { useContext, useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import DefaultPalette from "../theme/palette";
import { LanguageContext } from "../context/LanguageContext";
import { useAppDispatch } from "../hooks";
import { getLastWeekSessionValues } from "../reducers/plansSlice";
import { MainContext } from "../context/MainContext";
import { getStartAndEndDateOfLastWeek } from "../resources/simpleFunction";
import { Role } from "../utils/enums";
import DashboardWeekGraph from "./DashboardWeekGraph";
import DashboardYearGraph from "./DashboardYearGraph";
import CircularIndeterminate from "./CircularIndeterminate";
import EmptyComponent from "./EmptyComponent";
import { ListAlt } from "@mui/icons-material";

interface Props {
  plans: any;
}

const DashboardGraph = ({ plans }: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [dataWeekGraph, setDataWeekGraph] = useState<any>([]);
  const [dataYearGraph, setDataYearGraph] = useState<any>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let isActive = true;
    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive) {
          if (plans && isActive) {
            if (currentProfile.role === Role.Athlete) {
              const annualAthleteId = plans[0]?.annualPlanId;
              const res = await dispatch(
                getLastWeekSessionValues({
                  id: annualAthleteId,
                  start_week: getStartAndEndDateOfLastWeek()?.startDate,
                  end_week: getStartAndEndDateOfLastWeek()?.endDate,
                  name: plans[0]?.name,
                })
              );
              if (res.type.includes("fulfilled")) {
                setDataWeekGraph((prev: any) => {
                  const existingIndex = prev.findIndex(
                    (item: any) => item.id === annualAthleteId
                  );

                  if (existingIndex !== -1) {
                    prev[existingIndex] = {
                      ...prev[existingIndex],
                      ...res.payload,
                    };
                    return [...prev];
                  } else {
                    return [...prev, { ...res.payload }];
                  }
                });
              }
              const resYear = await dispatch(
                getLastWeekSessionValues({
                  id: annualAthleteId,
                  start_week: plans[0].start_date,
                  end_week: plans[0].end_date,
                  name: plans[0]?.name,
                })
              );
              if (resYear.type.includes("fulfilled")) {
                setDataYearGraph((prev: any) => {
                  const existingIndex = prev.findIndex(
                    (item: any) => item.id === annualAthleteId
                  );

                  if (existingIndex !== -1) {
                    prev[existingIndex] = {
                      ...prev[existingIndex],
                      ...resYear.payload,
                    };
                    return [...prev];
                  } else {
                    return [...prev, { ...resYear.payload }];
                  }
                });
              }
            } else {
              for (let i = 0; i < plans.length; i++) {
                const annualPlanId = plans[i]?.annualPlanId;
                let r = await dispatch(
                  getLastWeekSessionValues({
                    id: annualPlanId,
                    start_week: getStartAndEndDateOfLastWeek()?.startDate,
                    end_week: getStartAndEndDateOfLastWeek()?.endDate,
                    name: plans[i]?.name,
                  })
                );
                if (r.type.includes("fulfilled")) {
                  setDataWeekGraph((prev: any) => {
                    const existingIndex = prev.findIndex(
                      (item: any) => item.id === annualPlanId
                    );

                    if (existingIndex !== -1) {
                      prev[existingIndex] = {
                        ...prev[existingIndex],
                        ...r.payload,
                      };
                      return [...prev];
                    } else {
                      return [...prev, { ...r.payload }];
                    }
                  });
                }
                let rYear = await dispatch(
                  getLastWeekSessionValues({
                    id: annualPlanId,
                    start_week: plans[i]?.start_date,
                    end_week: plans[i]?.end_date,
                    name: plans[i]?.name,
                  })
                );
                if (rYear.type.includes("fulfilled")) {
                  setDataYearGraph((prev: any) => {
                    const existingIndex = prev.findIndex(
                      (item: any) => item.id === annualPlanId
                    );

                    if (existingIndex !== -1) {
                      prev[existingIndex] = {
                        ...prev[existingIndex],
                        ...rYear.payload,
                      };
                      return [...prev];
                    } else {
                      return [...prev, { ...rYear.payload }];
                    }
                  });
                }
              }
            }
          }
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };
    void onScreenOpening();

    return () => {
      isActive = false;
    };
  }, []);

  const styles = {
    gridStyle: {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px",
      fontSize: "18px",
    },
    boxStyle: {
      backgroundColor: palette.custom.background,
      padding: "10px",
      borderRadius: "8px",
      minHeight: "86vh",
    },
    center: {
      margin: "auto",
      marginTop: "0px",
    },
  };

  return (
    <Grid container sx={styles.boxStyle}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{i18n.plans.lastWeekLoad}</Typography>
      </Grid>
      {!loading &&
        dataWeekGraph &&
        dataYearGraph &&
        (dataWeekGraph === 0 || dataYearGraph.length === 0) && (
          <Grid item lg={12} sx={{ padding: "0.2rem" }}>
            <EmptyComponent
              paddingTop="5rem"
              fontSize={"h4"}
              text={i18n.appData.noData}
              icon={
                <ListAlt
                  sx={{ fontSize: "3rem", color: palette.custom.gray }}
                />
              }
            />
          </Grid>
        )}
      {!loading && dataWeekGraph && dataWeekGraph.length > 0 && (
        <Grid item lg={12} sx={{ padding: "0.2rem" }}>
          <DashboardWeekGraph data={dataWeekGraph} />
        </Grid>
      )}
      {!loading && dataYearGraph && dataYearGraph.length > 0 && (
        <Grid item lg={12} sx={{ padding: "0.2rem" }}>
          <DashboardYearGraph data={dataYearGraph} />
        </Grid>
      )}
      {loading && (
        <div style={styles.center}>
          <CircularIndeterminate />
        </div>
      )}
    </Grid>
  );
};

export default DashboardGraph;
