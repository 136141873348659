import React from "react";
import CustomCalendarList from "./CustomCalendarList";

interface Props {
  data?: any;
}

const CustomEventData = (props: Props): React.ReactElement => {
  const { data } = props;

  const groupedData = React.useMemo(() => {
    if (data && data.length > 0) {
      const grouped: any[] = [];
      data.forEach((item: any) => {
        const date = item?.competition?.date_of_competition;
        const competitionName = item?.competition?.name;
        const athleteName = item.athleteName;
        if (date && competitionName) {
          const key = `${date}-${competitionName}`;
          const groupIndex = grouped.findIndex((group) => group.key === key);
          if (groupIndex === -1) {
            grouped.push({
              key,
              date,
              competitionName,
              athleteName: [athleteName],
            });
          } else {
            grouped[groupIndex].athleteName.push(athleteName);
          }
        }
      });
      return grouped;
    }
    return [];
  }, [data]);

  const sortedGroupedData = React.useMemo(() => {
    if (groupedData.length > 0) {
      return groupedData.slice().sort((a, b) => {
        // Convert date strings to Date objects for comparison
        const dateA = new Date(a.date) as any;
        const dateB = new Date(b.date) as any;

        // Compare dates
        return dateA - dateB;
      });
    }
    return [];
  }, [groupedData]);
  return (
    <div
      style={{
        marginTop: "0.2rem",
        height: "30vh",
        marginBottom: "0.5rem",
        overflowY: "auto",
      }}
    >
      {sortedGroupedData && sortedGroupedData?.map((group, index) => (
        <div key={index} style={{ paddingTop: "5px" }}>
          <CustomCalendarList
            date={group.date}
            competitionName={group.competitionName}
            athleteName={group.athleteName}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(CustomEventData);
