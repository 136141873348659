import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, MenuItem } from "@mui/material";
import { LanguageContext } from "../app/context/LanguageContext";
import { useAppSelector } from "../app/hooks";
import ProfileCard from "../app/components/ProfileCard";
import ChangePasswordForm from "./forms/ChangePasswordForm";
import DeleteUserForm from "./forms/DeleteUserForm";
import ProfileForm from "./forms/ProfileForm";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsOfConditions from "./TermsOfConditions";
import { authService } from "../app/services/authService";
import { selectProfile } from "../app/reducers/profileSlice";
import NewRow from "../app/components/NewRow";
import DefaultPalette from "../app/theme/palette";
import SecurityIcon from "@mui/icons-material/Security";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LockResetIcon from "@mui/icons-material/LockReset";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";

type State = {
  privacy: boolean;
  terms: boolean;
  edit: boolean;
  change: boolean;
  delete: boolean;
};

const SettingsPage = (): React.ReactElement => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const navigate = useNavigate();
  const profile = useAppSelector(selectProfile);

  const [state, setState] = useState<State>({
    privacy: true,
    terms: false,
    edit: false,
    change: false,
    delete: false,
  });

  const handleOptionClick = (optionName: keyof State) => {
    const newState: State = {
      privacy: false,
      terms: false,
      edit: false,
      change: false,
      delete: false,
    };

    newState[optionName] = true;
    setState(newState);
  };

  const onLogout = async () => {
    try {
      await authService.logout();
      navigate("/");
      navigate(0);
    } catch (error) {}
  };

  const fields = [
    {
      name: "privacy",
      text: i18n.auth.privacyPolicy,
      icon: <SecurityIcon />,
      onClick: () => handleOptionClick("privacy" as keyof State),
    },
    {
      name: "terms",
      text: i18n.auth.termsOfConditions,
      icon: <AssignmentIcon />,
      onClick: () => handleOptionClick("terms" as keyof State),
    },
    {
      name: "edit",
      text: i18n.profile.editProfile,
      icon: <BorderColorIcon />,
      onClick: () => handleOptionClick("edit" as keyof State),
    },
    {
      name: "change",
      text: i18n.profile.changePassword,
      icon: <LockResetIcon />,
      onClick: () => handleOptionClick("change" as keyof State),
    },
    {
      name: "delete",
      text: i18n.profile.deleteAccount,
      icon: <DeleteIcon />,
      onClick: () => handleOptionClick("delete" as keyof State),
    },
    {
      text: i18n.profile.signOut,
      icon: <LogoutIcon />,
      color: true,
      onClick: onLogout,
    },
  ];

  return (
    <div>
      <NewRow height={"50px"} />
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        style={{ marginLeft: "20px" }}
      >
        <Grid
          item
          xs={4}
          sx={{
            borderRadius: "10px",
            backgroundColor: palette.custom.background,
            overflow: 'auto',
            height: '80vh'
          }}
        >
          <Grid item xs={10}>
            {profile?.value[0] && (
              <ProfileCard
                currentProfile={profile?.value[0]}
                backgroundColor={palette.custom.background}
              />
            )}
          </Grid>
          <Grid item xs={8} sx={{
            display: "flex",
            flexDirection: "column",
            height: "60%",
            justifyContent: "space-between"
          }}>
              {fields.map((option: any, idx: any) => {
                return (
                  <MenuItem
                    key={idx}
                    sx={{
                      display: "flex",
                      textAlign: "left",
                      lineHeight: "30px",
                      fontSize: "18px",
                      color: option.color
                        ? palette.custom.orange
                        : palette.custom.white,
                      backgroundColor: state[option.name as keyof State]
                        ? palette.custom.blue
                        : null,
                    }}
                    onClick={option.onClick}
                  >
                    <span
                      style={{
                        marginRight: "10px",
                        fontSize: "30px",
                      }}
                    >
                      {option.icon}
                    </span>
                    {option.text}
                  </MenuItem>
                );
              })}
          </Grid>
        </Grid>
        <Grid item xs={8} sx={{display: "flex", height: '80vh', overflow: "auto", alignItems: "center", justifyContent: "center",}}>
          <Paper
            elevation={3}
            sx={{
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {state.privacy && <div style={{ width: "80%" }}><PrivacyPolicyPage  /></div>}
            {state.terms && <div style={{ width: "80%" }}><TermsOfConditions /></div>}
            {state.change && (
              <ChangePasswordForm
                closeModal={() => handleOptionClick("change")}
              />
            )}
            {state.delete && (
              <div style={{ width: "55%" }}>
                <DeleteUserForm />
              </div>
            )}
            {state.edit && (
              <div style={{ width: "55%" }}>
                <ProfileForm
                  edit={true}
                  closeModal={() => handleOptionClick("edit")}
                />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
      <NewRow height={"200px"} />
    </div>
  );
};

export default SettingsPage;
