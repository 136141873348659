import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { setLogin } from "../../app/reducers/authSlice";
import { authService } from "../../app/services/authService";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";



const LoginForm = (props: any) => {
  const { i18n } = useContext(LanguageContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  const fields = [
    {
      name: "email",
      label: i18n.auth.lblEmail,
      type: "text",
    },
    {
      name: "password",
      label: i18n.auth.lblPassword,
      type: "password",
    },
  ];
  
  const init = {
    email: "",
    password: "",
  };

  const scheme = yup.object({
    email: yup
      .string()
      .email(i18n.auth.errors.invalidEmailFormat)
      .required(i18n.auth.errors.requiredEmail),
    password: yup.string().required(i18n.auth.errors.requiredPassword),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const res = await dispatch(setLogin(values));
      // const res = await authService.login(values)

      if (res.meta.requestStatus === "fulfilled") {
        navigate("/");
        navigate(0);
      } else {
        setError(i18n.auth.errors.notValidCredential);
      }

      setDisabled(false);
    } catch {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          text={i18n.auth.logIn}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default LoginForm;
