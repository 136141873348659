import React from "react";
import { InputLabel, TextField, Typography } from "@mui/material";
import DefaultPalette from "../theme/palette";

interface Props {
  formik?: any;
  type?: string;
  name: string;
  label?: string;
  placeholder?: string;
  width?: string;
  children?: any;
  select?: boolean;
  multiline?: boolean;
  numberOfRows?: number;
}

const CustomTextField = (props: Props | any): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");

  return (
    <React.Fragment>
      <TextField
        select={props.select ?? false}
        sx={{
          color: palette.custom.lightGray,
          width: props?.width ?? "100%",
          backgroundColor: palette.custom.background,
          input: { color: palette.custom.lightGray },
          outline: { color: palette.custom.background },
          borderColor: palette.custom.background,
          "& label.Mui-focused": {
            color: palette.custom.lightGray,
          },
          // "& MuiInputBase-root": {
          //   color: palette.custom.lightGray,
          // },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: palette.custom.lightGray,
              color: palette.custom.lightGray,
            },
            "&:hover fieldset": {
              borderColor: palette.custom.lightGray,
              color: palette.custom.lightGray,
            },
            "&.Mui-focused fieldset": {
              borderColor: palette.custom.lightGray,
              color: palette.custom.lightGray,
            },
          },
        }}
        id={props.name}
        placeholder={props.placeholder}
        label={props.label}
        type={props?.type ?? "text"}
        name={props.name}
        multiline={props.multiline ?? false}
        rows={props.numberOfRows ?? 1}
        variant="outlined"
        InputLabelProps={{
          shrink: props.multiline && false
        }}
        InputProps={{
          style: { color: palette.custom.lightGray },
        }}
        inputProps={{
          label: props.select && (
            <Typography variant="h1" sx={{ color: "red" }}>
              'sdfdsf
            </Typography>
          ),
        }}
        value={props.formik?.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        FormHelperTextProps={{
          sx: {
            background: palette.custom.blackBackground,
            margin: "0px",
          },
        }}
      >
        {props.children}
      </TextField>
    </React.Fragment>
  );
};

export default CustomTextField;
