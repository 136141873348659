import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import DefaultPalette from "../theme/palette";
import {
  AreaChart,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

interface Props {
  data: any;
}

const DashboardYearGraph = ({ data }: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [keyOfObject, setKeyOfObject] = useState<any>();

  useEffect(() => {
    let isActive = true;
    const onLoad = async () => {
      try {
        setLoading(true);

        if (isActive && data && data.length > 0) {
          const result: any = {};

          const startDate = new Date(data[0].start_week);
          const endDate = new Date(data[0].end_week);
          let currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            const monthKey = currentDate.toLocaleString("default", {
              month: "short",
            });
            result[monthKey] = { name: monthKey };

            data.forEach((item: any) => {
              const { name } = item;
              result[monthKey][name] = 0;
            });

            currentDate.setMonth(currentDate.getMonth() + 1);
          }

          data.forEach((item: any) => {
            const { name, loads, start_week, end_week } = item;
            const startDate = new Date(start_week);
            const endDate = new Date(end_week);

            loads.forEach((loadItem: any) => {
              const loadDate = new Date(loadItem.training_time);
              if (loadDate >= startDate && loadDate <= endDate) {
                const monthKey = loadDate.toLocaleString("default", {
                  month: "short",
                });

                result[monthKey][name] += loadItem.load;
              }
            });
          });

          const resultArray: any = Object.values(result);

          if (isActive) setChartData(resultArray);
          if (isActive && resultArray.length > 0)
            setKeyOfObject(Object.keys(resultArray[0]));
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    onLoad();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <Grid container xs={12}>
      {!loading && chartData && chartData.length > 0 && (
        <ResponsiveContainer width={"95%"} height={360}>
          <AreaChart
            data={chartData}
            margin={{ top: 20, right: 5, bottom: 20 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              verticalCoordinatesGenerator={(props) => [60, 60]}
            />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {keyOfObject.map((entry: any, idx: number) => {
              if (entry !== "name") {
                return (
                  <Area
                    type="monotone"
                    key={idx}
                    dataKey={entry}
                    stroke={`rgba(31, 95, 245, ${0.5 + idx * 0.1})`}
                    fill={palette.custom.darkGrayWithOpacity}
                    strokeWidth={3}
                  />
                );
              }
              return null;
            })}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Grid>
  );
};

export default DashboardYearGraph;
