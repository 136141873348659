import React, { useContext } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { LanguageContext } from "../context/LanguageContext";
import { Role } from "../utils/enums";

interface Props {
  currentProfile: any;
  backgroundColor?: any;
}

const ProfileCard = (props: Props) => {
  const { currentProfile, backgroundColor } = props;
  const { i18n } = useContext(LanguageContext);

  return (
    <Card style={{ backgroundColor: backgroundColor }}>
      <CardContent>
        <Grid
          container
          spacing={2}
          display={"flex"}
          justifyContent="center"
          marginLeft="30px"
          style={{ backgroundColor: backgroundColor }}
        >
          <Grid item xs={12} sm={8} textAlign="center">
            <IconButton>
              <Avatar sx={{ width: 66, height: 66 }} />
            </IconButton>
            {currentProfile.name && (
              <Typography variant="h5">{currentProfile.name}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            {currentProfile.sport && (
              <Typography
                color="textSecondary"
                noWrap
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                  fontSize: "18px",
                }}
              >
                <span>{i18n.profile.sportProfile}</span>
                <span style={{textOverflow: 'ellipsis',
                  overflow: 'hidden', paddingLeft: '5px',}}>{currentProfile.sport}</span>
              </Typography>
            )}
            {currentProfile.discipline && (
              <Typography
                color="textSecondary"
                noWrap
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                  // width: '90%',
                  textAlign: 'right',
                  fontSize: "18px",
                }}
              >
                <span>{i18n.profile.disciplineProfile}</span>
                <span style={{textOverflow: 'ellipsis',
                  overflow: 'hidden', paddingLeft: '5px',}}>{currentProfile.discipline}</span>
              </Typography>
            )}
            {currentProfile.role === Role.SportScienceAndMedicine && (
              <Typography
                color="textSecondary"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <span>{i18n.profile.role}</span>
                <span>{currentProfile.sport_science_and_medicine_role}</span>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingBottom: currentProfile.sport_science_and_medicine_role
              ? "0.5rem"
              : "0px",
            paddingTop: currentProfile.sport_science_and_medicine_role
              ? "0.5rem"
              : "0px",
          }}
        ></Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
