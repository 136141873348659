import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { deleteUser, setLogin } from "../../app/reducers/authSlice";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import { MainContext } from "../../app/context/MainContext";
import { authService } from "../../app/services/authService";
import DefaultPalette from "../../app/theme/palette";
import NewRow from '../../app/components/NewRow';

const DeleteUserForm = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useContext(MainContext);
  const palette = DefaultPalette("dark", "custom");

  const fields = [
    {
      name: "current_password",
      label: i18n.auth.lblPassword,
      type: "password",
    },
  ];

  const init = {
    current_password: "",
  };

  const scheme = yup.object({
    current_password: yup.string().required(i18n.auth.errors.requiredPassword),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);
      const data = {
        ...values,
        id: currentProfile.user,
      };

      const res = await dispatch(deleteUser(data));

      if (res.meta.requestStatus === "fulfilled") {
        authService.logout();
        navigate("/");
        navigate(0);
      } else {
        setError(i18n.auth.errors.notValidPassword);
      }

      setDisabled(false);
    } catch {
      setDisabled(false);
    }
  };

  return (
    <>
      <Typography
        style={{
          display: "flex",
          textAlign: "center",
          color: palette.custom.white,
        }}
      >
        {i18n.profile.deleteTitle}
      </Typography>
      <NewRow height={10} />
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default DeleteUserForm;
