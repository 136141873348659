import React, { useContext, useEffect, useMemo, useState } from "react";
import { MainContext } from "../../context/MainContext";
import { Role } from "../../utils/enums";
import CustomSelectDropdown from "../CustomSelectDropdown";

interface Props {
  plansForPicker?: any;
  defaultWeek: any;
  setCurrentPlan: (plan: any) => void;
  currentPlan: any;
  setValueCurrent: (value: any) => void;
  handleChange: any;
}

const CurrentWeeklyPlanPicker = (props: Props): React.ReactElement => {
  const {
    plansForPicker,
    defaultWeek,
    setCurrentPlan,
    currentPlan,
    setValueCurrent,
    handleChange,
  } = props;
  const { currentProfile } = useContext(MainContext);


  const valuesForPicker: any = useMemo(
    () =>
      plansForPicker?.map((plan: any) => ({
        value: plan?.annualPlanId,
        name: `Athlete ${plan?.name as string}`,
      })),
    [plansForPicker]
  );

  const [selectedValue, setSelectedValue] = useState(() => {
    if (currentProfile?.role === Role.Athlete && valuesForPicker?.length > 0) {
      return valuesForPicker[0].value;
    }

    return valuesForPicker.find(
      (item: any) => item.value === (currentPlan[0]?.id ?? currentPlan?.id)
    )?.value ?? "";
  });

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        if (isActive && currentProfile?.role === Role.Athlete) {
          setCurrentPlan(valuesForPicker[0]);
          handleChange(valuesForPicker[0]?.value)
          setValueCurrent(valuesForPicker[0]?.value)
        }
       
        if(currentPlan?.id) {
          handleChange(currentPlan?.id)
          setValueCurrent(currentPlan?.id)
        }
      } catch (error: any) {
        // Handle the error here if needed
      }
    };

    void onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [currentPlan?.id, currentProfile]);

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: "row",
        flex: "1",
      }}
    >
      {valuesForPicker && valuesForPicker.length > 0 && (
        <CustomSelectDropdown
          disabled={currentProfile?.role === Role.Athlete ? true : false}
          select={currentProfile?.role === Role.Athlete ? false : true}
          label={valuesForPicker.find(
            (item: any) => item.value === (currentPlan[0]?.id ?? currentPlan?.id)
          )?.name ?? `${defaultWeek ?? "Current"} Week`}
          value={currentProfile.role === Role.Athlete ? valuesForPicker[0]?.value : valuesForPicker.find(
            (item: any) => item.value === (currentPlan[0]?.id ?? currentPlan?.id)
          )?.value ?? ""}
          width={"70%"}
          selectValues={valuesForPicker}
          handleChange={(selectedItem: any) => {
            handleChange(selectedItem.target.value);
            setSelectedValue(selectedItem.target.value);
            setCurrentPlan(selectedItem.target.value);
            setValueCurrent(selectedItem.target.value);
          }}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    marginLeft: "2%",
  },
};

export default React.memo(CurrentWeeklyPlanPicker);
