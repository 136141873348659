export interface Profile {
  id?: string
  name?: string;
  date_of_birth?: string | null;
  discipline?: string | null;
  sport?: string;
  sport_science_and_medicine_role?: string;
  user?: string;
}

export class ProfileData {
    id?: string;
    name?: string;
    date_of_birth?: string | null;
    sport?: string;
    discipline?: string;
    sport_science_and_medicine_role?: string;
    user?: string;
  
    constructor(person: any) {
      this.id = person.id || person.pk;
      this.name = person.name || '';
      this.date_of_birth = person.date_of_birth || null;
      this.sport = person.sport || '';
      this.discipline = person.discipline || '';
      this.sport_science_and_medicine_role = person.sport_science_and_medicine_role || '';
      this.user = person.user || '';
    }
  }
  
    