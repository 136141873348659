import React from "react";
import SettingsPage from "../../pages/SettingsPage";
import DefaultPalette from "../theme/palette";

const CustomSettingsProfile = (): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");

  const styles: any = {
    athleteBox: {
      marginTop: "70px",
      height: "95vh",
    },
    dropDownProfileStyle: {
      position: "absolute",
      top: "3.5rem",
      right: "2.5rem",
      padding: "20px",
      backgroundColor: palette.custom.blackBackground,
      transform: "rotate(45deg)",
    },
  };
  return (
    <div style={styles.athleteBox}>
      <div style={{ backgroundColor: palette.custom.blackBackground }}>
        <div style={styles.dropDownProfileStyle}></div>
        <SettingsPage />
      </div>
    </div>
  );
};

export default CustomSettingsProfile;
