import React, { useContext } from "react";

import { Box, Container, Typography } from "@mui/material";
import DefaultPalette from "../app/theme/palette";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";

import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../app/context/LanguageContext";

const ForgotPasswordPage = () => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");
  const borderColor = palette.text.disabled;
  const navigate = useNavigate();

  const redirectHome = () => {
    navigate("/");
    navigate(0);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box textAlign="center" paddingTop="10%" paddingBottom="15%">
        <Typography
          variant="h1"
          onClick={redirectHome}
          sx={{ cursor: "pointer" }}
        >
          {i18n.auth.title}
        </Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: "50px 0 50px 0",
          padding: 10,
          width: "450px",
        }}
      >
        <Typography variant="h3" textAlign="center" paddingBottom="5%">
          {i18n.auth.forgotPassword}
        </Typography>
        <Typography variant="h2" textAlign="center" paddingBottom="10%">
          {i18n.auth.enterEmail}
        </Typography>
        <ForgotPasswordForm />
        <Link
          to="/"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: palette.custom.lightGray,
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ color: palette.custom.lightGray }}
            paddingTop="5%"
            onClick={redirectHome}
          >
            {i18n.auth.cancel}
          </Typography>
        </Link>
      </Box>
    </Container>
  );
};
export default ForgotPasswordPage;
