import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { getAnnualPlan, getAnnualPlans } from "../app/reducers/plansSlice";
import { MainContext } from "../app/context/MainContext";
import { Role } from "../app/utils/enums";
import {
  athleteNewestAnnualPlans,
  convertDateForSessionValues,
  getFormattedDate,
  getStartAndEndDateOfCurrentWeek,
  mapAnnualPlansToItems,
} from "../app/resources/simpleFunction";
import WeeklyTableView from "../app/components/weekly/WeeklyTableView";
import NewRow from "../app/components/NewRow";
import { format } from "date-fns";
import { Typography } from "@mui/material";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";
import DefaultPalette from "../app/theme/palette";
import { LanguageContext } from "../app/context/LanguageContext";

const CurrentWeekPage = (): React.ReactElement => {
  const { currentProfile } = useContext(MainContext);
  const [plans, setPlans] = useState<any>();
  const [weekStartDate, setWeekStartDate] = useState<any>(null);
  const [weekEndDate, setWeekEndDate] = useState<any>(null);
  const dispatch = useAppDispatch();
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = useContext(LanguageContext);

  useEffect(() => {
    let isActive = true;
    const onScreenOpening = async (): Promise<void> => {
      try {
        if (isActive) {
          const { startDate, endDate } = getStartAndEndDateOfCurrentWeek();
          setWeekStartDate(format(startDate, "dd/MM/yyyy"));
          setWeekEndDate(format(endDate, "dd/MM/yyyy"));
          const res = await dispatch(getAnnualPlans());

          if (res.type?.includes("fulfilled")) {
            setPlans(
              currentProfile?.role === Role.Athlete
                ? mapAnnualPlansToItems(await res?.payload)
                : mapAnnualPlansToItems(
                    athleteNewestAnnualPlans(await res?.payload)
                  )
            );
          }
        }
      } catch (error: any) {}
    };
    void onScreenOpening();

    return () => {
      isActive = false;
    };
  }, []);

  const handleChangeCurrent = useCallback(async (selectedItem: any) => {
    try {
      await dispatch(getAnnualPlan(selectedItem));
    } catch (err) {}
  }, []);

  return (
    <>
      <NewRow height={"100px"} />
      {plans && plans.length === 0 && (
        <EmptyComponent
          paddingTop="5rem"
          fontSize={"h4"}
          text={i18n.appData.noAnnualPlan}
          icon={
            <ListAlt sx={{ fontSize: "3rem", color: palette.custom.gray }} />
          }
        />
      )}
      {plans && plans.length > 0 && (
        <React.Fragment>
          {weekStartDate && weekEndDate && plans && (
            <WeeklyTableView
              handleChangeCurrent={handleChangeCurrent}
              weekStartDate={weekStartDate}
              weekEndDate={weekEndDate}
              plansForPicker={
                currentProfile.role === Role.Athlete
                  ? Array.of(plans[0])
                  : plans
              }
            />
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default CurrentWeekPage;
