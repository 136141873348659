import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, Typography } from "@mui/material";
import DefaultPalette from "../theme/palette";

interface Props {
  label: string;
  select?: boolean;
  width?: string;
  value?: any;
  selectValues: any;
  handleChange: any;
  disabled?: boolean;
  defaultValue?: any;
  defaultText?: any;
}

const CustomSelectDropdown = (props: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");

  const filteredSelectValues = props.selectValues.filter(
    (item: any) => item.value !== "Current"
  );

  return (
    <Select
      sx={{
        color: palette.custom.lightGray,
        width: props?.width ?? "100%",
        height: "42px",
        backgroundColor: palette.custom.background,
        borderRadius: "4px",
        outline: { color: palette.custom.background },
        borderColor: palette.custom.gray,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.custom.gray,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.custom.gray,
        },
      }}
      displayEmpty
      renderValue={
        props.value !== ""
          ? undefined
          : () => (
              <Typography sx={{ color: palette.custom.lightGray,  opacity: 0.5, }}>
                {props.label}
              </Typography>
            )
      }
      placeholder={props.label}
      variant="outlined"
      value={props.value}
      onChange={props.handleChange}
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
      }}
    >
      {filteredSelectValues.map((item: any, index: any) => (
        <MenuItem
          key={index}
          value={item.value}
          sx={{
            color: palette.custom.lightGray,
            backgroundColor: palette.custom.background,
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelectDropdown;
