import { Box, Typography } from '@mui/material';
import React from 'react';
import DefaultPalette from '../theme/palette';

interface Props {
    text?: string;
    textValue?: string;
}

const CustomTextLine = (props: Props): React.ReactElement => {
    const {text, textValue} = props;
    const palette = DefaultPalette("dark", "custom");

    const styles: any = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-bettween',

        },
        textValue: {
            textAlign: 'right',
            marginLeft: 'auto',
            width: '60%',
            wordWrap: 'break-word',
            alignSelf: 'center',

        }
    }
   
    return(
        <Box sx={styles.container}>
            <Typography variant='caption'>{text}</Typography>
            <Typography variant='body2' sx={styles.textValue}>{textValue}</Typography>
        </Box>
    )
}

export default CustomTextLine;