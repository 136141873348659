import API from '../api/api';
import {Profile, ProfileData} from '../../models/Profile';

export class ProfileService {
  async isProfilesEmpty(): Promise<number> {
    const data = await API.get(`/profiles/`);

    if (data?.data.length > 0) return 1;

    return 0
  }

  async postProfile(profile: Profile): Promise<ProfileData> {
    const data = await API.post('/profiles/', profile);

    return new ProfileData(data.data);
  }

  async putProfile(profile: any, id: string): Promise<ProfileData> {
    const data = await API.put(`/profiles/${id}/`, profile);

    return new ProfileData(data?.data);
  }

  async getProfiles(): Promise<any> {
    const data = await API.get('/profiles/');

    return data;
  }

  async getProfile(id: string): Promise<any> {
    const data = await API.get(`/profiles/${id}/`);

    return data;
  }

  async deleteProfile(id: string): Promise<void> {
    await API.delete(`/profiles/${id}/`);
    
  }
}

export const profileService = new ProfileService();
