import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { changePassword } from "../../app/reducers/authSlice";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import { MainContext } from "../../app/context/MainContext";

interface Props {
  closeModal?: any;
}

const ChangePasswordForm = (props: Props) => {
  const { closeModal } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);

  const fields = [
    {
      name: "current_password",
      label: i18n.plans.lblCurrentPassword,
      type: "password",
    },
    {
      name: "new_password",
      label: i18n.plans.lblNewPassword,
      type: "password",
    },
  ];
  
  const init = {
    current_password: "",
    new_password: "",
  };

  const scheme = yup.object({
    current_password: yup.string().required(i18n.plans.requiredField),
    new_password: yup
      .string()
      .min(8, i18n.appData.passValidationMin)
      .required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const res = await dispatch(changePassword(values));

      if (res.meta.requestStatus === "fulfilled") {
        closeModal()
      } else {
        setError(i18n.auth.errors.notValid);
      }

      setDisabled(false);
    } catch {
      setDisabled(false);
      setError(i18n.auth.errors.notValid);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default ChangePasswordForm;
