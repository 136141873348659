// ** Type Imports
import { PaletteMode } from "@mui/material";
import { ThemeColor } from "../../layouts/types";

const DefaultPalette = (mode: PaletteMode, themeColor: ThemeColor) => {
  // ** Vars
  // const lightColor = "#474747";
  const darkColor = "red";
  const mainColor = mode === "light" ? darkColor : darkColor;

  const primaryGradient = () => {
    if (themeColor === "primary") {
      return "red";
    } else if (themeColor === "secondary") {
      return "#9C9FA4";
    } else if (themeColor === "success") {
      return "#93DD5C";
    } else if (themeColor === "error") {
      return "#FF8C90";
    } else if (themeColor === "warning") {
      return "#FFCF5C";
    } else {
      return "#6ACDFF";
    }
  };

  return {
    customColors: {
      main: mainColor,
      primaryGradient: primaryGradient(),
      tableHeaderBg: mode === "light" ? "#F9FAFC" : "#3D3759",
    },
    common: {
      black: "#000",
      white: "#FFF",
    },
    mode: mode,
    primary: {
      light: "#1F5FF5",
      main: "#1F5FF5",
      dark: "#1F5FF5",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#9C9FA4",
      main: "#8A8D93",
      dark: "#777B82",
      contrastText: "#FFF",
    },
    success: {
      light: "#6AD01F",
      main: "#56CA00",
      dark: "#4CB200",
      contrastText: "#FFF",
    },
    error: {
      light: "#FF6166",
      main: "#FF4C51",
      dark: "#E04347",
      contrastText: "#FFF",
    },
    warning: {
      light: "#FFCA64",
      main: "#FFB400",
      dark: "#E09E00",
      contrastText: "#FFF",
    },
    info: {
      light: "#32BAFF",
      main: "#16B1FF",
      dark: "#139CE0",
      contrastText: "#FFF",
    },
    custom: {
      background: "#474747",
      lightGray: "#AAAAAA",
      lightGrayWithOpacity: "rgba(170, 170, 170, 0.5)",
      darkGrayWithOpacity: "rgba(37, 47, 64, 0.7)",
      darkGray: "#262626",
      blackBackground: "#2C2C2C",
      darkBlack: "#231F20",
      gray: "#777777",
      orange: "#FC5D2E",
      white: "#FFFFFF",
      blue: "#1F5FF5",
      red: "#ED1C24",
      darkBlue: "#313A50",
      gray2: "#373c40",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#D5D5D5",
      A200: "#AAAAAA",
      A400: "#616161",
      A700: "#303030",
    },
    text: {
      primary: "#1F5FF5",
      secondary: "#FFFFFF",
      disabled: "#707070",
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === "light" ? "#2C2C2C" : "red",
      default: mode === "light" ? "#2C2C2C" : "red",
      lightColor: mode === "light" ? "#474747" : "#474747",
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
  };
};

export default DefaultPalette;
