import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Container, Typography } from "@mui/material";

import NewRow from "../app/components/NewRow";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import logo from "../../src/app/resources/svg/logo.svg";

interface Props {
  isLoggedIn?: boolean;
  height?: string;
}

const TermsOfConditions = ({
  isLoggedIn = true,
  height
}: Props): React.ReactElement => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");
  const navigate = useNavigate();

  const styles: any = {
    containerStyle: {
      marginTop: isLoggedIn ? "0px" : "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      height: height ?? "80vh",
      overflowY: "auto",
      flexDirection: "column",
    },
    textStyle: {
      fontSize: "26px",
      color: palette.custom.white,
      fontWeight: "400",
    },
    textPosition: {
      display: "flex",
      alignItems: "flex-start",
      paddingTop: "10px",
    },
    textDigital: {
      fontSize: "20px",
      color: palette.custom.white,
      fontWeight: "400",
      marginTop: "10px",
    },
    titleStyle: {
      fontSize: "18px",
      color: palette.custom.white,
      fontWeight: "600",
      marginTop: "8px",
    },
    img: {
      cursor: "pointer",
      marginBottom: "1rem",
    },
  };
  return (
    <Container sx={styles.containerStyle}>
      {!isLoggedIn && (
        <Box
          sx={styles.img}
          component={"img"}
          src={logo}
          onClick={() => navigate("/")}
        />
      )}

      <Typography variant="h3">{i18n.termsOfConditions.terms}</Typography>
      <Typography variant="h2" style={styles.textPosition}>
        {i18n.termsOfConditions.termsText}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.acceptableUse}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.acceptableUsetext1}
      </Typography>
      <NewRow height={"5px"} />
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.acceptableUsetext2}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.acceptableUsetext3}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.intellectualProperty}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.intellectualPropertyText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.intellectualPropertyText2}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.userAccounts}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.userAccountsText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.userAccountsText2}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.userAccountsText3}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.disclaimer}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.disclaimerText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.disclaimerText2}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.limitationOfLiability}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.limitationOfLiabilityText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.limitationOfLiabilityText2}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.modificationsToTerms}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.modificationsToTermsText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.modificationsToTermsText2}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.governingLaw}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.governingLawText1}
      </Typography>
      <Typography style={styles.titleStyle}>
        {i18n.termsOfConditions.contactInfo}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.contactInfoText1}
      </Typography>
      <Typography variant="subtitle1">
        {i18n.termsOfConditions.contactInfoText2}
      </Typography>
      <Typography style={styles.textDigital}>
        {i18n.privacyPoliciy.digitalSport1}
      </Typography>
      <Typography style={styles.textDigital}>
        {i18n.privacyPoliciy.digitalSport2}
      </Typography>
      <Typography style={styles.textDigital}>
        {i18n.privacyPoliciy.digitalSport3}
      </Typography>
      <Typography style={styles.textDigital}>
        {i18n.privacyPoliciy.digitalSport4}
      </Typography>
    </Container>
  );
};

export default TermsOfConditions;
