import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DefaultPalette from "../theme/palette";

const CustomPasswordField = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const palette = DefaultPalette("dark", "custom");

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      sx={{
        color: palette.custom.lightGray,
        width: props?.width ?? "100%",
        backgroundColor: palette.custom.background,
        input: { color: palette.custom.lightGray },
        outline: { color: palette.custom.background },
        borderColor: palette.custom.background,
        "& label.Mui-focused": {
          color: palette.custom.lightGray,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: palette.custom.lightGray,
          },
          "&:hover fieldset": {
            borderColor: palette.custom.lightGray,
          },
          "&.Mui-focused fieldset": {
            borderColor: palette.custom.lightGray,
          },
        },
      }}
      id={props.name}
      label={props.label}
      name={props.name}
      variant="outlined"
      value={props.formik?.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ color: palette.custom.lightGray }}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={
        props.formik.touched[props.name] && props.formik.errors[props.name]
      }
      FormHelperTextProps={{
        sx: {
          background: palette.custom.blackBackground,
          margin: "0px",
        },
      }}
    />
  );
};

export default CustomPasswordField;
