import React, { useState } from "react";
import {
  hasDateOfCompetition,
  maxObjectsLength,
} from "../../resources/simpleFunction";
import DefaultPalette from "../../theme/palette";
import "./CustomWeeklyBody.css";
import { LanguageContext } from "../../context/LanguageContext";
import { setValueWeek } from "../../reducers/plansSlice";
import { useAppDispatch } from "../../hooks";
import CustomWeekTableRender from "./CustomWeeklyTableRender";

interface Props {
  data: any;
  headers: any;
  setDetailsModal: any;
  status: any;
  pastSession: any;
}

const CustomWeeklyBody: React.FC<Props> = ({
  data,
  headers,
  setDetailsModal,
  status,
  pastSession,
}: Props) => {
  const numRows = maxObjectsLength(data) > 4 ? maxObjectsLength(data) : 4;
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = React.useContext(LanguageContext);
  const [selectDay, setSelectDay] = useState<any>(null);
  const dispatch = useAppDispatch();

  const handleSelectDay = (index: any) => {
    setSelectDay(index);
  };

  const handleRowClick = async (rowData: any) => {
    try {
      setDetailsModal(true);
      await dispatch(setValueWeek(rowData));
    } catch (error) {}
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: "12px",
        textAlign: "center",
      }}
    >
      {(selectDay === null || selectDay === undefined) && (
        <React.Fragment>
          {data &&
            data.map((d: any, idx: any) => {
              const keys: any = d?.objects;
              const isOrange =
                keys && keys.some((e: any) => hasDateOfCompetition(e));

              return (
                <CustomWeekTableRender
                  key={idx}
                  data={data}
                  headers={headers}
                  isOrange={isOrange}
                  selectDay={selectDay}
                  idx={idx}
                  palette={palette}
                  i18n={i18n}
                  numRows={numRows}
                  handleSelectDay={handleSelectDay}
                  handleRowClick={handleRowClick}
                  pastSession={pastSession}
                  status={status}
                />
              );
            })}
        </React.Fragment>
      )}
      {selectDay !== null && selectDay !== undefined && (
        <React.Fragment>
          {data &&
            (() => {
              const keys: any = data[selectDay]?.objects;
              const isOrange =
                keys && keys.some((e: any) => hasDateOfCompetition(e));
              return (
                <CustomWeekTableRender
                  data={data}
                  headers={headers}
                  selectDay={selectDay}
                  palette={palette}
                  isOrange={isOrange}
                  idx={undefined}
                  i18n={i18n}
                  pastSession={pastSession}
                  numRows={numRows}
                  handleRowClick={handleRowClick}
                  handleSelectDay={handleSelectDay}
                  status={status}
                />
              );
            })()}
        </React.Fragment>
      )}
    </div>
  );
};

export default CustomWeeklyBody;
