import React, {useContext, Dispatch} from 'react';

import language from '../resources/languages';

export type LanguageType = 'en' | 'zh';

interface LanguageContextLang {
  i18n: typeof language.en;
  language: LanguageType;
  setLanguage: Dispatch<any>;
}

export const LanguageContext = React.createContext<LanguageContextLang>({
  i18n: language.en,
  language: 'en',
  setLanguage: () => {
    throw new Error('setLanguage() not implemented');
  },
});

export const useLanguageContext = (): LanguageContextLang => {
  return useContext(LanguageContext);
};
