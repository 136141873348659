import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { MainContext } from "../../app/context/MainContext";
import { LanguageContext } from "../../app/context/LanguageContext";
import { postProfile, putProfile } from "../../app/reducers/profileSlice";
import { Role } from "../../app/utils/enums";
import { format } from "date-fns";

interface Props {
  edit?: boolean;
  closeModal?: any;
  closeFirstModal?: any;
}

const ProfileForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { currentProfile, setCurrentProfile } = useContext(MainContext);
  const { i18n } = useContext(LanguageContext);
  const [disabled, setDisabled] = useState(false);

  let fields = [];

  if (
    currentProfile.role === Role.Athlete ||
    currentProfile.role === Role.Coach
  ) {
    fields.push(
      {
        name: "sport",
        label: i18n.profile.sport,
        type: "text",
      },
      {
        name: "discipline",
        label: i18n.profile.discipline,
        type: "text",
      }
    );
  }

  if (currentProfile.role === Role.SportScienceAndMedicine) {
    fields.push({
      name: "sport_science_and_medicine_role",
      label: i18n.profile.sportScienceRole,
      type: "text",
    });
  }

  const init = {
    sport: currentProfile.sport || "",
    discipline: currentProfile.discipline || "",
    sport_science_and_medicine_role:
      currentProfile.sport_science_and_medicine_role || "",
  };

  const scheme = yup.object({
    sport:
      currentProfile.role === Role.Athlete || currentProfile.role === Role.Coach
        ? yup.string().required(i18n.profile.sportRequired)
        : yup.string(),
    discipline:
      currentProfile.role === Role.Athlete || currentProfile.role === Role.Coach
        ? yup.string().required(i18n.profile.disciplineRequired)
        : yup.string(),
    sport_science_and_medicine_role:
      currentProfile.role === Role.SportScienceAndMedicine
        ? yup.string().required(i18n.profile.sportRoleRequired)
        : yup.string(),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const formData = {
        ...values,
        name: `${currentProfile.first_name} ${currentProfile.last_name}`,
        user: props.edit ? currentProfile.user : currentProfile.id,
      };

      let res: any;

      if (!props.edit) res = await dispatch(postProfile(formData));
      if (props.edit)
        res = await dispatch(
          putProfile({ ...formData, id: currentProfile.id })
        );

      if (res?.type.includes("fulfilled")) {
        setCurrentProfile({
          ...currentProfile,
          name: res?.payload?.name,
          sport:
            currentProfile.role === Role.Athlete ||
            currentProfile.role === Role.Coach
              ? res?.payload?.sport
              : null,
          discipline:
            currentProfile.role === Role.Athlete ||
            currentProfile.role === Role.Coach
              ? res?.payload?.discipline
              : null,
          sport_science_and_medicine_role:
            currentProfile.role === Role.SportScienceAndMedicine
              ? res?.payload?.sport_science_and_medicine_role
              : null,
        });
        if (props.edit) props.closeModal();
        else {
          props.closeFirstModal();
        }
      }

      setDisabled(false);
    } catch {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          text={i18n.appData.save}
        />
      )}
    </>
  );
};
export default ProfileForm;
