// ** MUI Imports
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

// ** Types
import { BlankLayoutProps } from "./types";
import MyAppBar from "../components/NavigationBar";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../context/MainContext";
import { useAppDispatch } from "../hooks";
import { getUser } from "../reducers/authSlice";
import { getProfile } from "../reducers/profileSlice";
import { Typography } from "@mui/material";
import { LanguageContext } from "../context/LanguageContext";
import FooterBar from "../components/FooterBar";
import { appstore, playstore } from "../resources/images";
import { Role } from '../utils/enums';
import { getTrainingTemplates } from '../reducers/plansSlice';

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100vh",

  // For V1 Blank layout pages
  "& .content-center": {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5),
  },

  // For V2 Blank layout pages
  "& .content-right": {
    display: "flex",
    minHeight: "100vh",
    overflowX: "hidden",
    position: "relative",
  },
}));

const UserLayout = ({ children }: BlankLayoutProps) => {
  const { setCurrentProfile } = useContext(MainContext);
  const { i18n } = useContext(LanguageContext);
  const dispatch = useAppDispatch();
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const conditionSize = 1056; //enable 11 inches and above 11*96

  useEffect(() => {
    if (!hasFetchedProfile) {
      const fetchProfileData = async () => {
        try {
          const [userResponse, profileResponse] = await Promise.all([
            dispatch(getUser()),
            dispatch(getProfile()),
          ]);

          if (userResponse.type.includes("rejected")) {
            localStorage.clear();
            window.location.replace("/");
            return;
          }

          const user = userResponse.payload;
          const profile = profileResponse.payload || {};

          if(user.role === Role.Coach) {
            dispatch(getTrainingTemplates())
          }

          setCurrentProfile({ ...user, ...profile });
          setHasFetchedProfile(true);
        } catch (error) {
          // Handle error
        }
      };

      fetchProfileData();
    }
  }, [dispatch, setCurrentProfile, hasFetchedProfile]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <MyAppBar />
      <BlankLayoutWrapper className="layout-wrapper">
        {windowWidth > conditionSize && (
          <Box
            className="app-content"
            sx={{
              minHeight: "100vh",
              overflowX: "hidden",
              position: "relative",
            }}
          >
            {children}
          </Box>
        )}
        {windowWidth <= conditionSize && (
          <Box
            className="app-content"
            sx={{
              minHeight: "100vh",
              overflowX: "hidden",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ paddingTop: "15rem", textAlign: "center", width: "75%" }}
            >
              {i18n.appData.goToMobile}
            </Typography>
            <a target="_blank" href="#" rel="noreferrer">
              <img src={appstore.src} alt="App Store" />
            </a>
            <a target="_blank" href="#" rel="noreferrer">
              <img src={playstore.src} alt="Play Store" />
            </a>
          </Box>
        )}
      </BlankLayoutWrapper>
    </>
  );
};

export default UserLayout;
