import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store/store';
import { profileService } from '../services/profileService';
import {Profile, ProfileData } from '../../models/Profile';


export interface ProfileState {
  value: Profile[]
  loading: boolean
}

const initialState: ProfileState = {
  value: [],
  loading: false,
};

export const postProfile = createAsyncThunk(
  'profile/post',
  async (values: Profile): Promise<ProfileData> => {
    const response = await profileService.postProfile(values)
    // The value we return becomes the `fulfilled` action payload
    return new ProfileData(response);
  }
);

export const putProfile = createAsyncThunk(
  'profile/put',
  async (values: any): Promise<ProfileData> => {
    const response = await profileService.putProfile(values, values.id)
    // The value we return becomes the `fulfilled` action payload
    return new ProfileData(response);
  }
);

export const getProfile = createAsyncThunk(
  'profile/get',
  async (): Promise<ProfileData> => {
    const response = await profileService.getProfiles()
    // The value we return becomes the `fulfilled` action payload
    return new ProfileData(response.data[0]);
  }
);



export const profileSlice = createSlice({
  name: 'profile_part',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Profile>) => {
      state.value.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProfile.fulfilled, (state, action) => {
        state.loading = false
        state.value.push(action.payload);
      })
      .addCase(postProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(putProfile.fulfilled, (state, action) => {
        state.loading = false
        state.value = [action.payload];
      })
      .addCase(putProfile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false
        state.value.push(action.payload);
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading = false;
      })
  },
});


export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
