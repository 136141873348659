export const importanceValue = [
  {
    name: "* Control",
    value: "PREPARATION",
  },
  {
    name: "** Performance",
    value: "PERFORMANCE",
  },
  {
    name: "*** Decisive",
    value: "DECISIVE",
  },
];

export const importanceDisplayObject: Record<string, string> = {
  PREPARATION: '*',
  PERFORMANCE: '**',
  DECISIVE: '***',
};

export const importanceDisplayCardObject: Record<string, string> = {
  PREPARATION: '* Control',
  PERFORMANCE: '** Performance',
  DECISIVE: '*** Decisive',
};

export enum Role {
  Coach = "COACH",
  Athlete = "ATHLETE",
  SportScienceAndMedicine = "SPORT_SCIENCE_AND_MEDICINE",
}

export enum WeekStatus {
  Past = 'past',
  Current = 'current',
  Future = 'future',
}

export const sessionTypes = [
  {
    name: "Technical",
    value: "TECHNICAL",
  },
  {
    name: "Tactical",
    value: "TACTICAL",
  },
  {
    name: "Physical",
    value: "PHYSICAL",
  },
  {
    name: "Recovery",
    value: "RECOVERY",
  },
];

export const rpeValues = [
  { name: "1 - Very light activity", value: "1" },
  { name: "2 - Light activity level one", value: "2" },
  { name: "3 - Light activity level two", value: "3" },
  { name: "4 - Moderate activity level one", value: "4" },
  { name: "5 - Moderate activity level two", value: "5" },
  { name: "6 - Moderate activity level three", value: "6" },
  { name: "7 - Vigorous activity level one", value: "7" },
  { name: "8 - Vigorous activity level two", value: "8" },
  { name: "9 - Very hard activity", value: "9" },
  { name: "10 - Max effort activity", value: "10" },
];

export const rpe: any = {
  1: "Very light activity",
  2: "Light activity level one",
  3: "Light activity level two",
  4: "Moderate activity level one",
  5: "Moderate activity level two",
  6: "Moderate activity level three",
  7: "Vigorous activity level one",
  8: "Vigorous activity level two",
  9: "Very hard activity",
  10: "Max effort activity",
};

export const sessionObject: Record<string, string> = {
  TECHNICAL: 'Technical',
  TACTICAL: 'Tactical',
  PHYSICAL: 'Physical',
  RECOVERY: 'Recovery',
};

