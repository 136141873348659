import React, {useContext} from 'react';
import MainUserData from '../../models/MainUserData';

const initialState: MainUserData = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  role: '',
  user: '',
  sport: '',
  discipline: '',
  sport_science_and_medicine_role: '',
};

interface MainContextProps {
  isAuthenticated: boolean;
  setAuthenticated: (v: boolean) => void;
  currentProfile: MainUserData;
  setCurrentProfile: (v: MainUserData) => void;
}

export const MainContext = React.createContext<MainContextProps>({
  isAuthenticated: false,
  setAuthenticated: (v: boolean) => {},
  currentProfile: initialState,
  setCurrentProfile: (v: MainUserData) => {},
});

export const useMainContext = (): MainContextProps => {
  return useContext(MainContext);
};
