import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import {
  postCompetition,
  putCompetition,
  selectPlan,
} from "../../app/reducers/plansSlice";
import { importanceValue } from "../../app/utils/enums";
import { format } from "date-fns";

interface Props {
  edit?: boolean;
  annual?: any;
  competition?: any;
  closeModal: any;
}

const CompetitionForm = (props: Props): React.ReactElement => {
  const { edit, annual, competition, closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);

  const fields = [
    {
      name: "date_of_competition",
      label: i18n.plans.dateComp,
      type: "date",
    },
    {
      text: i18n.plans.eventDetails,
      type: "title",
    },
    {
      name: "name",
      label: i18n.plans.nameComp,
      placeholder: i18n.plans.placName,
      type: "text",
    },
    {
      name: "location",
      label: i18n.plans.location,
      placeholder: i18n.plans.placLocation,
      type: "text",
    },
    {
      name: "importance",
      label: i18n.plans.importance,
      placeholder: i18n.plans.placImportance,
      type: "select",
      selectValues: importanceValue,
    },
    {
      name: "target",
      label: i18n.plans.target,
      placeholder: i18n.plans.placTarget,
      type: "text",
    },
  ];

  const init = {
    name: competition?.name ?? "",
    date_of_competition: competition?.date_of_competition
      ? new Date(competition?.date_of_competition)
      : new Date(annualPlans?.valuesWeek[0]?.day).getTime() >
        new Date().getTime()
      ? new Date(annualPlans?.valuesWeek[0]?.day)
      : new Date(),
    location: competition?.location || "",
    target: competition?.target || "",
    importance: competition?.importance || "",
  };

  const scheme = yup.object({
    name: yup.string().required(i18n.plans.requiredField),
    date_of_competition: yup.string().required(i18n.plans.requiredField),
    target: yup.string().required(i18n.plans.requiredField),
    location: yup.string().required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const formData = {
        ...values,
        date_of_competition: format(
          new Date(values.date_of_competition),
          "yyyy-MM-dd"
        ),
        annual_plan: annual.id,
      };

      const editData = {
        ...values,
        date_of_competition: format(
          new Date(values.date_of_competition),
          "yyyy-MM-dd"
        ),
        annual_plan: annual.id,
        id: competition?.id,
      };

      let res;

      if (!edit) res = await dispatch(postCompetition(formData));

      if (edit) res = await dispatch(putCompetition(editData));

      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
        // navigate('/')
        // navigate(0)
      } else {
        setError("Error!");
      }

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default CompetitionForm;
