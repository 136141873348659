import React, { useContext } from "react";
import DefaultPalette from "../../theme/palette";
import { Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { LanguageContext } from "../../context/LanguageContext";

interface Props {
  dataGraph: any;
}

const AnnualGraph = (props: Props): React.ReactElement => {
  const { dataGraph } = props;
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const styles: any = {
    headerCell: {
      border: `1px solid ${palette.custom.background}`,
      backgroundColor: palette.custom.lightGray,
      padding: "5px",
      height: "120px",
      minWidth: "106px",
    },
    headerText: {
      color: palette.custom.white,
      fontWeight: "bold",
    },
    cellStyle: {
      border: `0.5px solid ${palette.custom.lightGray}`,
      position: "relative",
    },
    rotateText: {
      padding: "3px",
      color: palette.custom.white,
      position: "absolute",
      top: "50%",
      left: "50%",
      fontSize: "12px",
      fontWeight: "normal",
      transformOrigin: "center center",
      transform: "translate(-50%, -50%) rotate(-90deg)",
      textAlign: "center",
    },
    text: {
      color: palette.custom.white,
      fontSize: "12px",
      fontWeight: "normal",
      textAlign: "center",
      padding: "0.5px",
    },
    graph: {
      padding: "0px",
      "& .MuiLineElement-root": {
        stroke: palette.custom.orange,
      },
      "& .MuiMarkElement-root": {
        display: "none",
      },
      "& .MuiChartsAxis-tick": {
        display: "none",
      },
      "& .MuiChartsAxis-tickLabel": {
        display: "none",
      },
      "& .MuiChartsAxis-line": {
        display: "none",
      },
    },
  };

  return (
    <table
      style={{
        border: `0.5px solid ${palette.custom.lightGray}`,
        borderCollapse: "collapse",
        padding: "0px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <thead style={{ width: "106px" }}>
        <tr>
          <th style={styles.headerCell}>
            <Typography style={styles.headerText}>
              {i18n.plans.weeklyLoad}
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody style={{ margin: "auto" }}>
        <tr style={{ height: "120px", display: "flex" }}>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            {dataGraph && dataGraph.length > 0 && (
              <LineChart
                series={[{ curve: "linear", data: dataGraph }]}
                tooltip={{ trigger: "none" }}
                width={1600}
                sx={styles.graph}
                height={180}
              />
            )}
            {dataGraph && dataGraph.length === 0 && (
              <Typography style={styles.headerText}>
                {i18n.plans.noLoads}
              </Typography>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AnnualGraph;
