import React, { useContext } from "react";
import { Role } from "../../utils/enums";
import CustomSelectDropdown from "../CustomSelectDropdown";
import { LanguageContext } from "../../context/LanguageContext";
import { sortAnnualPlans } from "../../resources/simpleFunction";

interface Props {
  annualPlans?: any;
  onSelect?: any;
  currentProfile?: any;
  defaultWeek?: any;
  allWeeks?: any;
  setCurrentWeek: (value: any) => void;
  currentMc?: any;
}

const WeeklyPickerAthlete = (props: Props): React.ReactElement => {
  const {
    annualPlans,
    onSelect,
    currentProfile,
    defaultWeek,
    allWeeks,
    setCurrentWeek,
    currentMc,
  } = props;
  const { i18n } = useContext(LanguageContext);

  return (
    <div
      style={{
        ...styles.container,
        flexDirection: "row",
        flex: "1",
      }}
    >
      <CustomSelectDropdown
        disabled={currentProfile?.role === Role.Athlete ? true : false}
        select={currentProfile?.role === Role.Athlete ? false : true}
        label={
          annualPlans?.value?.id
            ? `${annualPlans?.value?.season?.name as string} - ${
                annualPlans?.value?.athlete?.name as string
              }`
            : i18n.plans.seasonAthlete
        }
        value={annualPlans?.value?.id ?? ""}
        width={"70%"}
        selectValues={sortAnnualPlans(annualPlans.values)?.map((plan: any) => {
          return {
            value: plan?.id,
            name: `${plan?.season?.name as string} - ${
              plan?.athlete?.name as string
            }`,
          };
        })}
        handleChange={(selectedItem: any) => {
          setCurrentWeek(selectedItem?.target.value)
          onSelect(selectedItem?.target.value);
        }}
      />
    </div>
  );
};

const styles = {
  container: {
    marginLeft: "2%",
  },
};

export default React.memo(WeeklyPickerAthlete);
