import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import LoginForm from "./forms/LoginForm";
import { Box, Container, Typography } from "@mui/material";
import DefaultPalette from "../app/theme/palette";
import { LanguageContext } from "../app/context/LanguageContext";

const LoginPage = () => {
  const { i18n } = useContext(LanguageContext);
  const navigate = useNavigate();
  const palette = DefaultPalette("dark", "custom");
  const borderColor = palette.text.disabled;

  const redirectRegister = () => {
    navigate("/register");
    navigate(0);
  };

  const redirectForgotPassword = () => {
    navigate("/password");
    navigate(0);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box textAlign="center" paddingTop="10%" paddingBottom="15%">
        <Typography variant="h1">{i18n.auth.title}</Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: "50px 0 50px 0",
          padding: 10,
          width: "450px",
        }}
      >
        <Typography variant="h3" textAlign="center" paddingBottom="5%">
          {i18n.auth.login}
        </Typography>
        <Typography variant="h2" textAlign="center">
          {i18n.auth.signInContinue}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          paddingBottom="10%"
        >
          <Typography variant="h2" textAlign="center">
            {i18n.auth.alreadyAccount}
          </Typography>
          <Link
            to="/register"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "white",
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              onClick={redirectRegister}
            >
              {i18n.auth.linkRegister}
            </Typography>
          </Link>
        </Box>
        <LoginForm />
        <Link
          to="/password"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: palette.custom.lightGray,
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ color: palette.custom.lightGray }}
            paddingTop="5%"
            onClick={redirectForgotPassword}
          >
            {i18n.auth.linkForgotPasssword}
          </Typography>
        </Link>
      </Box>
    </Container>
  );
};
export default LoginPage;
