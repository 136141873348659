import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../app/context/MainContext";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getAnnualPlan,
  getAnnualPlans,
  getWeekSessionValues,
  selectPlan,
} from "../app/reducers/plansSlice";
import CustomModal from "../app/components/CustomModal";
import CompetitionForm from "./forms/CompetitionForm";
import { Box } from "@mui/material";
import CustomBlueButton from "../app/components/CustomBlueButton";
import { Role } from "../app/utils/enums";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import CircularIndeterminate from "../app/components/CircularIndeterminate";
import NewRow from "../app/components/NewRow";
import AnnualPlanPicker from "../app/components/annual/AnnualPlanPicker";
import AnnualTable from "../app/components/annual/AnnualTable";
import AnnualGraph from "../app/components/annual/AnnualGraph";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";

const AnnualPlanPage = (): React.ReactElement => {
  const { currentProfile } = useContext(MainContext);
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const [eventModal, setEventModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadValues, setLoadValues] = useState<any>();
  const annualPlans = useAppSelector(selectPlan);
  const dispatch = useAppDispatch();

  const onSelect = async (selectedItem: any) => {
    try {
      setLoading(true);
      let res = await dispatch(getAnnualPlan(selectedItem));
      if (res.meta.requestStatus === "fulfilled") {
        const loads = await dispatch(
          getWeekSessionValues({
            id: res.payload?.id,
            start_week: `${res.payload?.season?.start_date}T00:00:00`,
            end_week: `${res.payload?.season?.end_date}T23:59:59`,
          })
        );
        if (loads.meta.requestStatus === "fulfilled")
          if (loads?.payload.length > 0) {
            setLoadValues(loads?.payload.map((v: any) => v.load));
          } else {
            setLoadValues([]);
          }
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isActive = true;
    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive) await dispatch(getAnnualPlans());
        if (isActive && annualPlans?.value?.id)
          await onSelect(annualPlans?.value?.id);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };
    onScreenOpening();
    void onScreenOpening();
    return () => {
      isActive = false;
    };
  }, [dispatch]);

  const styles = {
    textStyle: {
      fontSize: "18px",
      color: palette.custom.lightGray,
    },
    textChooseAthelete: {
      fontSize: "26px",
      color: palette.custom.lightGray,
      marginTop: 30,
    },
    boxRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100vw",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <NewRow height={"90px"} />

      <Box sx={styles.boxRow}>
        <Box
          sx={{
            display: "flex",
            width: "50vw",
          }}
        >
          <AnnualPlanPicker
            annualPlans={annualPlans}
            onSelect={onSelect}
            currentProfile={currentProfile}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "50vw",
          }}
        >
          {annualPlans?.valueSeason?.id &&
            currentProfile.role !== Role.Athlete && (
              <CustomBlueButton
                onPress={() => {
                  setEventModal(true);
                }}
                label={i18n.plans.addEvent}
                width={"40%"}
              />
            )}
        </Box>
      </Box>

      {!loading && (
        <>
          {annualPlans?.value.id === undefined && (
            <EmptyComponent
              paddingTop="5rem"
              fontSize={"h4"}
              text={i18n.appData.noAnnualPlan}
              icon={
                <ListAlt
                  sx={{ fontSize: "3rem", color: palette.custom.gray }}
                />
              }
            />
          )}
          {annualPlans?.value.id !== undefined && <AnnualTable />}
          <NewRow height={"15px"} />
          {annualPlans?.weekSessionValues && loadValues && (
            <AnnualGraph dataGraph={loadValues ?? []} />
          )}
        </>
      )}
      {loading && <CircularIndeterminate />}
      {eventModal && (
        <CustomModal
          open={eventModal}
          onClose={() => {
            setEventModal(false);
          }}
          text={i18n.plans.addEvent}
        >
          <CompetitionForm
            annual={annualPlans.value}
            closeModal={() => {
              setEventModal(false);
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default AnnualPlanPage;
