import React, {useState} from 'react';

import {MainContext} from './MainContext';
import MainUserData from '../../models/MainUserData';

const initialState = {
  id: '',
  email: '',
  role: '',
  user: '',
  name: '',
  club: '',
  date_of_birth: '',
  weapon: ''
};

const MainStore = (props: any): React.ReactElement => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [currentProfile, setCurrentProfile] = useState<MainUserData>(initialState);

  return (
    <MainContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        currentProfile,
        setCurrentProfile,
      }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainStore;
