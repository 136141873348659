import React from "react";
import { format, parseISO } from "date-fns";

import { Typography } from "@mui/material";
import filePen from "../../resources/svg/filePen.svg";
import plusReport from "../../resources/svg/plusReport.svg";
import orangeFilePen from "../../resources/svg/orangeFilePen.svg";
import orangePlusReport from "../../resources/svg/orangePlusReport.svg";
import { WeekStatus, sessionObject } from "../../utils/enums";
import { format as formatTz, utcToZonedTime } from "date-fns-tz";
import CustomBar from "../CustomBar";
import CustomWeeklyHeader from "../CustomWeeklyHeader";

const CustomWeekTableRender = ({
  data,
  headers,
  selectDay,
  palette,
  i18n,
  numRows,
  handleRowClick,
  isOrange,
  handleSelectDay,
  idx,
  status,
  pastSession,
}: any): React.ReactElement => {
  const pastData = status === WeekStatus.Past;
  const futureData = status === WeekStatus.Future;
  const current = status === WeekStatus.Current;

  const styles: any = {
    cell: {
      border: `1px solid ${palette.custom.gray}`,
      color: palette.custom.white,
      fontSize: pastData
        ? "20px"
        : (futureData || current) && idx === undefined
        ? "20px"
        : "12px",
      fontWeight: "400",
      height: "4rem",
      padding: "5px",
    },
    loadCell: {
      border: `1px solid ${palette.custom.gray}`,
      color: palette.custom.white,
      fontSize: pastData
        ? "20px"
        : (futureData || current) && idx === undefined
        ? "20px"
        : "12px",
      fontWeight: "400",
      padding: "5px",
      height: "350px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "end",
      paddingBottom: "15px",
      borderCollapse: "collapse",
    },
    cellHeader: {
      border: `1px solid ${palette.custom.gray}`,
      color: palette.custom.white,
      fontSize:
        pastData && idx === undefined
          ? "20px"
          : (futureData || current) && idx === undefined
          ? "20px"
          : "10px",
      fontWeight: "400",
      height: "4rem",
      padding: "5px",
      wordWrap: "break-word",
      textAlign: "center",
      backgroundColor: `${palette.custom.gray}`,
    },
    mainHeader: {
      textAlign: "center",
      fontSize: "22px",
      alignItems: "center",
      fontWeight: "700",
      cursor: "pointer",
      color: palette.custom.white,
    },
    mainSubheader: {
      textAlign: "center",
    },
    mainCellHeader: {
      flex: 1,
      alignItems: "center",
      height: "50px",
      cursor: idx === undefined ? "normal" : "pointer",
      border: `1px solid ${palette.custom.gray}`,
      backgroundColor: isOrange
        ? palette.custom.orange
        : palette.custom.darkBlue,
    },
    typo: {
      color: palette.custom.white,
      fontSize:
        pastData && idx === undefined
          ? "20px"
          : (futureData || current) && idx === undefined
          ? "20px"
          : "12px",
      textAlign:
        pastData && idx === undefined
          ? "center"
          : (futureData || current) && idx === undefined
          ? "center"
          : "left",
    },
    typoLoad: {
      color: palette.custom.white,
      fontSize:
        pastData && idx === undefined
          ? "20px"
          : (futureData || current) && idx === undefined
          ? "20px"
          : "14px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
    },
    typoTitleLoad: {
      textAlign: "center",
      color: palette.custom.white,
      fontSize: "20px",
      fontWeight: "bold",
    },
    buttonStyle: {
      backgroundColor: "transparent",
      color: palette.custom.white,
      fontSize: "30px",
      marginRight: "40px",
      marginLeft: "40px",
      border: "none",
      outline: "none",
      fontWeight: "bold",
      cursor: "pointer",
    },
  };

  const headerPastWeek = [
    { text: i18n.plans.headerPastWeek.trainingEvent, colspan: 1 },
    { text: i18n.plans.headerPastWeek.description, colspan: 3 },
    { text: i18n.plans.headerPastWeek.totalTime, colspan: 1 },
    { text: i18n.plans.headerPastWeek.rpe, colspan: 1 },
    { text: i18n.plans.headerPastWeek.load, colspan: 1 },
  ];

  const headerFutureWeek = [
    { text: i18n.plans.headerFutureWeek.time, colspan: 1 },
    { text: i18n.plans.headerFutureWeek.trainingEvent, colspan: 1 },
    { text: i18n.plans.headerFutureWeek.trainingFocus, colspan: 1 },
    { text: i18n.plans.headerFutureWeek.report, colspan: 1 },
  ];

  const headerMinWeek = [
    { text: i18n.plans.headerMinWeek.trainingEvent, colspan: 2 },
    { text: i18n.plans.headerMinWeek.description, colspan: 4 },
    { text: i18n.plans.headerMinWeek.report, colspan: 2 },
  ];

  const colspanOfHeader =
    idx === undefined && pastData
      ? 7
      : idx === undefined && (futureData || current)
      ? 4
      : 8;

  const renderHeader = (headers: Array<any>) => {
    return headers.map((header, index) => (
      <th
        key={index}
        colSpan={header.colspan}
        style={{
          ...styles.cellHeader,
        }}
      >
        {header?.text}
      </th>
    ));
  };

  const renderTimeCell = (time: any, colspan: any) => {
    return (
      <td style={styles.cell} colSpan={colspan}>
        <Typography noWrap sx={{ ...styles.typo, textAlign: "center" }}>
          {time &&
            formatTz(utcToZonedTime(time, "Etc/UTC"), "HH:mm", {
              timeZone: "Etc/UTC",
            })}
        </Typography>
      </td>
    );
  };

  const renderCell = (data: any, colspan: any) => {
    return (
      <td style={styles.cell} colSpan={colspan}>
        <Typography noWrap sx={{ ...styles.typo, textAlign: "center" }}>
          {data}
        </Typography>
      </td>
    );
  };

  const loads = pastSession.filter((v: any) =>
    v.training_time.includes(data[selectDay ?? idx].day)
  );

  const renderTitleLoad = (colspan: any) => {
    return (
      <td
        colSpan={colspan}
        style={{
          ...styles.mainCellHeader,
          border: "none",
          borderLeft: `1px solid ${palette.custom.darkBlue}`,
          borderRight: `1px solid ${palette.custom.darkBlue}`,
          backgroundColor: palette.custom.darkBlue,
          width: `${100.5 * colspan}%`,
        }}
      >
        {idx === 3 && (
          <Typography sx={styles.typoTitleLoad}>
            {i18n.plans.headers.weeklyLoad}
          </Typography>
        )}
        {idx === undefined && (
          <Typography sx={styles.typoTitleLoad}>
            {i18n.plans.headers.dailyLoad}
          </Typography>
        )}
      </td>
    );
  };

  const renderLoadsCell = (loads: any, colspan: any) => {
    return (
      <td
        colSpan={colspan}
        style={{ ...styles.loadCell, width: `${100.5 * colspan}%` }}
      >
        {loads &&
          loads.length > 0 &&
          loads.map((value: any, idx: any) => {
            return (
              <CustomBar key={idx} value={value} color={palette.custom.gray} />
            );
          })}
        {loads && loads.length === 0 && (
          <Typography sx={styles.typoLoad}>{"No loads"}</Typography>
        )}
      </td>
    );
  };

  const renderReportCell = (val: any) => {
    const isCompetition: boolean = val?.date_of_competition;
    const reports = isCompetition ? val?.reports : val?.report;
    return (
      <td style={styles.cell} colSpan={idx !== undefined ? 2 : 1}>
        <Typography
          noWrap
          sx={{
            color: palette.custom.white,
            textAlign: "center",
          }}
        >
          {reports?.length === 0 ? (
            <img
              src={isCompetition ? orangePlusReport : plusReport}
              alt="Plus report Icon"
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClick(val)}
            />
          ) : (
            reports?.length > 0 && (
              <img
                src={isCompetition ? orangeFilePen : filePen}
                alt="File pen Icon"
                onClick={() => handleRowClick(val)}
              />
            )
          )}
        </Typography>
      </td>
    );
  };

  return (
    <table style={{ tableLayout: "fixed", width: "100%" }}>
      <thead>
        <tr>
          <th
            colSpan={colspanOfHeader}
            style={{
              ...styles.mainCellHeader,
              width:
                status === WeekStatus.Past
                  ? "100%"
                  : status !== WeekStatus.Past
                  ? "80%"
                  : "66.66%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {idx === undefined && selectDay > 0 && (
                <button
                  style={styles.buttonStyle}
                  onClick={() => handleSelectDay(selectDay - 1)}
                >
                  &lt;
                </button>
              )}
              {idx === undefined && selectDay === 0 && (
                <div style={{ ...styles.buttonStyle, width: "35px" }}>
                  {"  "}
                </div>
              )}
              <Typography
                sx={styles.mainHeader}
                onClick={() => handleSelectDay(idx)}
              >
                {headers[idx ?? selectDay]?.text}
              </Typography>
              {idx === undefined && selectDay < 6 && (
                <button
                  style={styles.buttonStyle}
                  onClick={() => handleSelectDay(selectDay + 1)}
                >
                  &gt;
                </button>
              )}
              {idx === undefined && selectDay === 6 && (
                <div style={{ ...styles.buttonStyle, width: "35px" }}>
                  {"  "}
                </div>
              )}
            </div>
            {idx === undefined && (
              <Typography
                variant="body2"
                sx={styles.mainSubheader}
                onClick={() => handleSelectDay(idx)}
              >
                {format(parseISO(data[idx ?? selectDay]?.day), "dd. MM. yyyy.")}
              </Typography>
            )}
          </th>
        </tr>
        <tr style={{ width: "100%" }}>
          {idx === undefined && pastData && renderHeader(headerPastWeek)}
          {idx === undefined &&
            (futureData || current) &&
            renderHeader(headerFutureWeek)}
          {idx !== undefined && renderHeader(headerMinWeek)}
        </tr>
      </thead>
      <tbody>
        {[...Array(numRows)].map((_, rowIndex) => {
          const val = data[selectDay ?? idx].objects[rowIndex] || {};
          const competitionName = val?.location
            ? `${val.name} - ${val.location}`
            : val.name;
          const competitionNameColspan =
            idx === undefined && pastData
              ? 6
              : idx === undefined && (futureData || current)
              ? 3
              : 6;

          const timeColspan = idx !== undefined ? 2 : 1;
          const sessionFocusDescription = (
            <React.Fragment>
              {sessionObject[val.session_type] ?? ""}
              <br />
              {val.session_description ?? ""}
            </React.Fragment>
          );

          const descriptionColspan = idx === undefined ? 3 : 1;

          return val.date_of_competition ? (
            <tr
              key={rowIndex}
              onClick={() => {
                idx === undefined &&
                  val?.date_of_competition &&
                  handleRowClick(val);
              }}
              style={{
                cursor:
                  idx === undefined && val?.date_of_competition
                    ? "pointer"
                    : "default",
              }}
            >
              {renderCell(competitionName, competitionNameColspan)}
              {renderReportCell(val)}
            </tr>
          ) : (
            <tr
              key={rowIndex}
              onClick={() => {
                idx === undefined && val?.day_time && handleRowClick(val);
              }}
              style={{
                cursor:
                  idx === undefined && val?.day_time ? "pointer" : "default",
              }}
            >
              {(idx !== undefined ||
                ((futureData || current) && idx === undefined)) &&
                renderTimeCell(val.day_time, timeColspan)}
              {idx !== undefined && renderCell(sessionFocusDescription, 4)}
              {idx === undefined &&
                renderCell(sessionObject[val?.session_type] ?? "", 1)}
              {idx === undefined &&
                (futureData || current) &&
                renderCell(sessionObject[val?.session_description] ?? "", 1)}
              {idx === undefined &&
                pastData &&
                renderCell(val.session_description ?? "", descriptionColspan)}
              {idx === undefined &&
                pastData &&
                renderCell(
                  pastSession &&
                    pastSession.find((el: any) => el?.training === val?.id)
                      ?.duration_in_minutes,
                  1
                )}
              {idx === undefined &&
                pastData &&
                renderCell(
                  pastSession &&
                    pastSession.find((el: any) => el?.training === val?.id)
                      ?.rpe,
                  1
                )}

              {idx === undefined &&
                pastData &&
                renderCell(
                  pastSession.find((el: any) => el?.training === val?.id)
                    ?.load ??
                    (val?.report && (
                      <img
                        src={plusReport}
                        alt="Plus report Icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRowClick(val)}
                      />
                    )),
                  1
                )}

              {(futureData || current || (pastData && idx !== undefined)) &&
                renderReportCell(val)}
            </tr>
          );
        })}
        <tr>{renderTitleLoad(colspanOfHeader)}</tr>
        <tr>{renderLoadsCell(loads, colspanOfHeader)}</tr>
      </tbody>
    </table>
  );
};

export default CustomWeekTableRender;
