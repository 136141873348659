import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, Container, Box } from "@mui/material";
import DefaultPalette from "../theme/palette";
import { Link } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";

const FooterBar = () => {
  const palette = DefaultPalette("dark", "custom");
  const { i18n } = useContext(LanguageContext);

  const styles = {
    appBar: {
      backgroundColor: palette.custom.darkBlack,
    },
    boxStyle: {
      display: "flex",
      alignItems: "center",
    },
    linkStyle: {
      cursor: "pointer",
      textDecoration: "underline",
      color: "white",
      marginLeft: "10px",
    },
  };

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Container style={{ maxWidth: "100%" }}>
        <Toolbar>
          <Box style={styles.boxStyle}>
            <Link to="/privacyPolicy" style={styles.linkStyle}>
              <Typography variant="h2">{i18n.auth.privacyPolicy}</Typography>
            </Link>
            <Link to="/termsOfConditions" style={styles.linkStyle}>
              <Typography variant="h2">
                {i18n.auth.termsOfConditions}
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default FooterBar;
