import React, { useContext } from "react";
import logo from "../../src/app/resources/svg/logo.svg";
import { Box, Container, Typography } from "@mui/material";
import NewRow from "../app/components/NewRow";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import { useNavigate } from "react-router-dom";

interface Props {
  isLoggedIn?: boolean;
  height?: string;
}

const PrivacyPolicyPage = ({ isLoggedIn = true, height }: Props): React.ReactElement => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");
  const navigate = useNavigate();

  const styles: any = {
    containerStyle: {
      marginTop: isLoggedIn ? "0px" : "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      height: height ?? "80vh",
      overflowY: "auto",
      flexDirection: "column",
    },
    textStyle: {
      fontSize: "26px",
      color: palette.custom.white,
      fontWeight: "400",
    },
    textPosition: {
      display: "flex",
      alignItems: "flex-start",
      paddingTop: "10px",
    },
    textDigital: {
      fontSize: "20px",
      color: palette.custom.white,
      fontWeight: "400",
      marginTop: "10px",
    },
    titleStyle: {
      fontSize: "18px",
      color: palette.custom.white,
      fontWeight: "600",
      marginTop: "8px",
    },
    img: {
      cursor: "pointer",
      marginBottom: "1rem",
    },
  };
  return (
    <>
      <Container sx={styles.containerStyle}>
        {!isLoggedIn && (
          <Box
            sx={styles.img}
            component={"img"}
            src={logo}
            onClick={() => navigate("/")}
          />
        )}
        <Typography variant="h3">{i18n.privacyPoliciy.adaptifyApp}</Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.lastUpdated}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.aboutPrivicy}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.information}
        </Typography>
        <NewRow height={"5px"} />
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.personalInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.deviceInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.usegeData}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.useOfInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.useCollectedInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.emailAddress}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.disclosureOfInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.sharePersonalInformation}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.discloseInf}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.datSecurity}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.security}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.childrenPrivacy}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.childrenText}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.choices}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.choicesText1}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.choicesText2}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.links}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.linksText1}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.changes}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.changesText1}
        </Typography>
        <Typography style={styles.titleStyle}>
          {i18n.privacyPoliciy.contactUs}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.contactUsText1}
        </Typography>
        <Typography variant="subtitle1">
          {i18n.privacyPoliciy.contactUsText2}
        </Typography>
        <Typography style={styles.textDigital}>
          {i18n.privacyPoliciy.digitalSport1}
        </Typography>
        <Typography style={styles.textDigital}>
          {i18n.privacyPoliciy.digitalSport2}
        </Typography>
        <Typography style={styles.textDigital}>
          {i18n.privacyPoliciy.digitalSport3}
        </Typography>
        <Typography style={styles.textDigital}>
          {i18n.privacyPoliciy.digitalSport4}
        </Typography>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;
