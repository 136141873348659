import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authSlice from '../reducers/authSlice';
import profileSlice from '../reducers/profileSlice';
import plansSlice from '../reducers/plansSlice';

export const store: any = configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
    plans: plansSlice,
    },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
