// ** MUI Imports
import { Theme } from "@mui/material/styles";

// ** Theme Config Imports
import themeConfig from "../../../configs/themeConfig";
import DefaultPalette from "../palette";

const Button = (theme: Theme) => {
  const palette = DefaultPalette("dark", "custom");
  const shadowColor = palette.custom.darkGray;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary,
          fontWeight: 700,
          borderRadius: "99px",
          lineHeight: 1.71,
          fontSize: "16px",
          letterSpacing: "0.3px",
          textTransform: "none",
          padding: `${theme.spacing(1.875, 3)}`,
          boxShadow: `0px 4px 4px ${shadowColor}`,
        },
        contained: {
          padding: `${theme.spacing(1.875, 5.5)}`,
          "&:hover": {
            boxShadow: `0px 10px 10px ${shadowColor}`,
          },
        },
        outlined: {
          padding: `${theme.spacing(1.625, 5.25)}`,
        },
        sizeSmall: {
          padding: `${theme.spacing(1, 2.25)}`,
          "&.MuiButton-contained": {
            padding: `${theme.spacing(1, 3.5)}`,
          },
          "&.MuiButton-outlined": {
            padding: `${theme.spacing(0.75, 3.25)}`,
          },
        },
        sizeLarge: {
          padding: `${theme.spacing(2.125, 5.5)}`,
          "&.MuiButton-contained": {
            padding: `${theme.spacing(2.125, 6.5)}`,
          },
          "&.MuiButton-outlined": {
            padding: `${theme.spacing(1.875, 6.25)}`,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple,
      },
    },
  };
};

export default Button;
