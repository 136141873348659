import React from "react";
import DefaultPalette from "../../theme/palette";
import { Typography } from "@mui/material";
import { getFormattedDate } from "../../resources/simpleFunction";
import { importanceDisplayObject } from "../../utils/enums";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

interface Props {
  monthData: any;
  calendarData: any;
  weeks: any;
}

const CustomAnnualBodyTable = (props: Props): React.ReactElement => {
  const { monthData, calendarData, weeks } = props;
  const navigate = useNavigate();
  const palette = DefaultPalette("dark", "custom");

  const tableWidth: any = `calc(95vw/${weeks})`
  const cellWidth: any = `calc(95vw/${weeks}*${monthData?.weeks?.length})`

  const onRowPress = (week: any) => {
    navigate("/weeklyPlan", {
      state: {
        weekStartDate: getFormattedDate(week?.week_start_date),
        weekEndDate: getFormattedDate(week?.week_end_date),
        mc: week?.number,
        allWeeks: JSON.stringify(calendarData),
      },
    });
  };

  const styles: any = {
    cellStyle: {
      width: `${100 / monthData?.weeks?.length}%`,
      border: `0.3px solid ${palette.custom.lightGray}`,
      position: "relative",
    },
    rotateText: {
      padding: "3px",
      color: palette.custom.white,
      position: "absolute",
      top: "50%",
      left: "50%",
      fontSize: "0.7rem",
      fontWeight: "normal",
      transformOrigin: "center center",
      transform: "translate(-50%, -50%) rotate(-90deg)",
      textAlign: "center",
    },
    text: {
      color: palette.custom.white,
      fontSize: "0.7rem",
      fontWeight: "normal",
      textAlign: "center",
      padding: "0px",
    },
  };

  const renderRow = (week: any, type: string, style?: any, rotate?: any) => {
    let content = "";

    switch (type) {
      case "date":
        const startDate = format(parseISO(week?.week_start_date), "dd/MM");
        const endDate = format(parseISO(week?.week_end_date), "dd/MM");
        content = `${startDate} - ${endDate}`;
        break;
      case "weekNumber":
        content = week.number;
        break;
      case "competitionName":
        content = week.competition?.location
          ? `${week.competition?.name} - ${week.competition?.location}`
          : week.competition?.name;
        break;
      case "importance":
        content = importanceDisplayObject[week.competition?.importance];
        break;
      case "target":
        content = week.competition?.target;
        break;
      default:
        break;
    }

    return (
      <td
        key={week.number}
        style={{ ...styles.cellStyle, ...style }}
        onClick={() => onRowPress(week)}
      >
        <Typography noWrap sx={{ ...styles.text, ...rotate }}>
          {content}
        </Typography>
      </td>
    );
  };

  return (
    <React.Fragment>
      {monthData?.weeks && (
        <table
          style={{
            // border: `0.5px solid ${palette.custom.lightGray}`,
            borderCollapse: "collapse",
            padding: "0px",
            minWidth: `${tableWidth}`,
          }}
        >
          <thead>
            <tr style={{ height: "40px" }}>
              <th
                colSpan={monthData?.weeks?.length}
                style={{
                  width: `${cellWidth}`,
                  border: `0.5px solid ${palette.custom.lightGray}`,
                }}
              >
                <Typography sx={{ ...styles.text, fontWeight: "bold" }}>
                  {monthData?.month}
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody style={{ cursor: "pointer" }}>
            <tr style={{ height: "120px" }}>
              {monthData?.weeks?.map((week: any) =>
                renderRow(
                  week,
                  "date",
                  { backgroundColor: palette.custom.darkBlue },
                  { ...styles.rotateText, width: "180px" }
                )
              )}
            </tr>
            <tr style={{ height: "40px" }}>
              {monthData?.weeks?.map((week: any) =>
                renderRow(week, "weekNumber")
              )}
            </tr>
            <tr style={{ height: "180px" }}>
              {monthData?.weeks?.map((week: any) =>
                renderRow(
                  week,
                  "competitionName",
                  {},
                  { ...styles.rotateText, width: "180px" }
                )
              )}
            </tr>
            <tr style={{ height: "40px" }}>
              {monthData?.weeks?.map((week: any) =>
                renderRow(week, "importance")
              )}
            </tr>
            <tr style={{ height: "40px" }}>
              {monthData?.weeks?.map((week: any) =>
                renderRow(
                  week,
                  "target",
                  {},
                  { ...styles.rotateText, width: "40px" }
                )
              )}
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default CustomAnnualBodyTable;
