import {
  List,
  ListItem,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import DefaultPalette from "../theme/palette";
import { Role } from "../utils/enums";
import { useContext } from 'react';
import { MainContext } from '../context/MainContext';

const CustomCalendarList = (props: any) => {
  const { date, competitionName, athleteName } = props;
  const {currentProfile} = useContext(MainContext)
  const palette = DefaultPalette("dark", "custom");

  const maxAthletesToShow = 5;

  return (
    <Card
      sx={{
        width: "99%",
        height: "90px",
        borderRadius: "4px",
        backgroundColor: palette.custom.gray,
        border: `1px solid ${palette.custom.gray}`,
      }}
    >
      <CardContent sx={{ paddingTop: "5px", paddingLeft: "10px" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={8}>
            <Grid container direction="column" justifyContent="space-around">
              <Typography noWrap color={palette.custom.white} fontSize={"14px"}>
                {date}
              </Typography>
              <Typography
                noWrap
                style={{ wordBreak: "break-word" }}
                color={palette.custom.white}
                fontWeight={"700"}
                fontSize={"16px"}
              >
                {competitionName}
              </Typography>
              {currentProfile.role !== Role.Athlete && (
                <List style={{ padding: "0px", display: "flex", width: "30%" }}>
                  {athleteName
                    .slice(0, maxAthletesToShow)
                    .map((athlete: any, athleteIndex: any) => {
                      const namesArray = athlete.split(" ");
                      const initials = namesArray?.length > 1 ?
                        namesArray[0][0]?.toUpperCase() +
                        namesArray[1][0]?.toUpperCase() : namesArray[0][0]?.toUpperCase()

                      return (
                        <ListItem
                          key={athleteIndex}
                          style={{
                            display: "flex",
                            padding: "0px",
                            marginRight: "3px",
                          }}
                        >
                          <div
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                              backgroundColor: palette.custom.white,
                              color: palette.custom.blue,
                              fontWeight: "700",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {initials}
                          </div>
                        </ListItem>
                      );
                    })}

                  {athleteName.length > maxAthletesToShow && (
                    <ListItem key="more-athletes" style={{ padding: "0px" }}>
                      <Typography
                        noWrap
                        style={{
                          color: palette.custom.white,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "5px",
                          fontSize: "12px",
                        }}
                      >
                        +{athleteName.length - maxAthletesToShow} more
                      </Typography>
                    </ListItem>
                  )}
                </List>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomCalendarList;
