import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Container, Typography } from "@mui/material";
import DefaultPalette from "../app/theme/palette";
import { LanguageContext } from "../app/context/LanguageContext";

const SignUpPage = () => {
  const { i18n } = useContext(LanguageContext);

  const navigate = useNavigate();
  const palette = DefaultPalette("dark", "custom");
  const borderColor = palette.text.disabled;

  const redirectLogin = () => {
    navigate("/");
    navigate(0);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box textAlign="center" paddingTop="10%" paddingBottom="15%">
        <Typography variant="h1">{i18n.auth.title}</Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: "50px 0 50px 0",
          padding: 10,
          width: "450px",
        }}
      >
        <Typography variant="h3" textAlign="center" paddingBottom="5%">
          {i18n.auth.register}
        </Typography>
        <Typography variant="h2" textAlign="center" paddingBottom="5%">
          {i18n.auth.contactUs}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          paddingBottom="10%"
        >
          <Typography variant="h2" textAlign="center">
            {i18n.auth.alreadyRegistered}
          </Typography>
          <Link
            to="/"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "white",
            }}
          >
            <Typography variant="h2" textAlign="center" onClick={redirectLogin}>
              {i18n.auth.logIn}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
export default SignUpPage;
