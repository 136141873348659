import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import DefaultPalette from "../theme/palette";
import { getStartAndEndDateOfLastWeek } from "../resources/simpleFunction";

interface Props {
  data: any;
}

const DashboardWeekGraph = ({ data }: Props): React.ReactElement => {
  const palette = DefaultPalette("dark", "custom");
  const startDate = getStartAndEndDateOfLastWeek().startDate;
  const endDate = getStartAndEndDateOfLastWeek().endDate;
  const [dateList, setDateList] = useState<any>([]);

  const calculatePercentage = (loadPerDay: number, maxLoad: number) => {
    if (loadPerDay === 0 && maxLoad === 0) {
      return 0;
    }
    return (loadPerDay / maxLoad) * 85;
  };

  const maxLoad = Math.max(
    ...data.map((v: any) => {
      let loadPerDay = 0;

      v.loads.forEach((item: any) => {
        loadPerDay += item.load;
      });

      return loadPerDay;
    })
  );

  useEffect(() => {
    let isActive = true;
    const onLoad = async () => {
      try {
        const uniqueDates = new Set();

        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          const formattedDate = currentDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
          });

          uniqueDates.add(formattedDate);
          currentDate.setDate(currentDate.getDate() + 1);
        }

        const uniqueDateArray = Array.from(uniqueDates);
        if (isActive) setDateList(uniqueDateArray);
      } catch (err) {}
    };
    onLoad();

    return () => {
      isActive = false;
    };
  }, []);

  const styles = {
    gridStyle: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      fontSize: "1rem",
    },
    dateStyle: {
      fontSize: "14px",
      marginTop: 5,
      marginBottom: 5,
      textAlign: "center",
      transform: "rotate(-80deg)",
    },
    blueBar: (loadPerDay: number, maxLoad: number) => ({
      height: "10px",
      margin: "2px",
      borderRadius: "4px 4px",
      width: `${calculatePercentage(loadPerDay, maxLoad)}%`,
      backgroundColor: palette.custom.blue,
    }),
    dashedBar: (loadPerDay: number, maxLoad: number) => ({
      height: "1px",
      width: `${100 - calculatePercentage(loadPerDay, maxLoad)}%`,
      border: `0.2px dashed ${palette.custom.lightGray}`,
      marginLeft: "2px",
    }),
  };

  return (
    <Grid container>
      <Grid item container lg={12}>
        {data &&
          data.map((v: any, idx: any) => {
            let loadPerDay = 0;

            v.loads.forEach((item: any) => {
              loadPerDay += item.load;
            });

            return (
              <React.Fragment key={idx}>
                <Grid item lg={5}>
                  <Typography variant="body2" sx={{ lineHeight: "1.5rem" }}>
                    {v?.name}
                  </Typography>
                </Grid>
                <Grid item lg={7}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "0.3rem",
                    }}
                  >
                    {loadPerDay > 0 && (
                      <div style={styles.blueBar(loadPerDay, maxLoad)}></div>
                    )}
                    {loadPerDay < maxLoad && (
                      <div style={styles.dashedBar(loadPerDay, maxLoad)}></div>
                    )}
                    {calculatePercentage(loadPerDay, maxLoad) < 100 && (
                      <div style={styles.dashedBar(loadPerDay, maxLoad)}></div>
                    )}
                  </div>{" "}
                </Grid>
              </React.Fragment>
            );
          })}
      </Grid>
      <Grid item lg={5}></Grid>
      <Grid
        item
        container
        lg={7}
        direction={"row"}
        justifyContent={"space-between"}
      >
        {dateList &&
          dateList.map((v: any, idx: any) => {
            return (
              <Typography variant="body2" sx={styles.dateStyle} key={idx}>
                {v}
              </Typography>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default DashboardWeekGraph;
