import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NewRow from "../app/components/NewRow";
import WeeklyTableView from "../app/components/weekly/WeeklyTableView";
import { useAppSelector } from '../app/hooks';
import { selectPlan } from '../app/reducers/plansSlice';

const WeeklyPlanPage = (): React.ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()
  const annualPlans = useAppSelector(selectPlan)
  const { weekStartDate, weekEndDate, mc, allWeeks } = location.state;

  const [weekStart, setWeekStartDate] = useState(null);
  const [weekEnd, setWeekEndDate] = useState(null);
  const [mcNumber, setMc] = useState(null);
  const [allWeeksDays, setAllWeeks] = useState(null);

  useEffect(() => {
    let isActive = true;
    const onScreenOpening = async (): Promise<void> => {
      try {
        if(isActive) setWeekStartDate(weekStartDate);
        if(isActive) setWeekEndDate(weekEndDate);
        if(isActive) setMc(mc);
        if(isActive) setAllWeeks(allWeeks);
        if(isActive && annualPlans?.value?.length === 0) navigate('/annualPlan')
      } catch (error: any) {}
    };

    onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [allWeeks, mc, weekEndDate, weekStartDate]);

  return (
    <>
      <NewRow height={"100px"} />
      {weekStart && weekEnd && (
        <WeeklyTableView
          weekStartDate={weekStart}
          weekEndDate={weekEnd}
          mc={mcNumber}
          allWeeks={allWeeksDays}
        />
      )}
    </>
  );
};

export default WeeklyPlanPage;
