export interface Login {
    email: string
    password: string
}

export class LoginData {
    access: string;
    refresh: string;
  
    constructor(login: any) {
      this.access = login.access || '';
      this.refresh = login.refresh || '';
    }
  }
