import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  text?: string;
  icon?: any;
  fontSize?: any;
  paddingTop?: string;
}

const EmptyComponent = (props: Props): React.ReactElement => {
  const { text, icon, fontSize, paddingTop } = props;
  const boxStyle = {
    textAlign: "center",
    paddingTop: paddingTop ?? 5,
  };

  return (
    <Box component={"div"} sx={boxStyle}>
      {icon && icon}
      {/* {text && (
        <Typography variant={fontSize ? fontSize : "h5"} color="textSecondary">
          {text}
        </Typography>
      )} */}
    </Box>
  );
};

export default EmptyComponent;
