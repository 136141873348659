import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store/store";
import { authService } from "../services/authService";
import { Login, LoginData } from "../../models/Login";

export interface LoginState {
  value: LoginData[];
  loading: boolean;
}

const initialState: LoginState = {
  value: [],
  loading: false,
};

export const setLogin = createAsyncThunk(
  "login/post",
  async (values: Login): Promise<LoginData> => {
    const response = await authService.login(values);
    // The value we return becomes the `fulfilled` action payload
    return new LoginData(response);
  }
);

export const getUser = createAsyncThunk("auth_user/get", async () => {
  const response = await authService.getUser();
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const deleteUser = createAsyncThunk(
  "auth_user/delete",
  async (values: any) => {
    const response = await authService.deleteUser(values);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  "auth_user/changePassword",
  async (values: any) => {
    const response = await authService.changePassword(values);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth_user/forgotPassword",
  async (values: any) => {
    const response = await authService.forgotPassword(values);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth_part",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginData>) => {
      state.value.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(setLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.value.push(action.payload);
        localStorage.setItem("refreshToken", action.payload.refresh);
        localStorage.setItem("token", action.payload.access);
      })
      .addCase(setLogin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value.push(action.payload);
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value.push(action.payload);
      })
      .addCase(deleteUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.value.push(action.payload);
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.value.push(action.payload);
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.loading = false;
      });

  },
});

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
