import React, { useContext, useEffect } from "react";
import CustomEventCalendar from "../app/components/CustomEventCalendar";
import { getAnnualPlans, selectPlan } from "../app/reducers/plansSlice";
import { MainContext } from "../app/context/MainContext";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Box, Grid, Typography } from "@mui/material";
import AthleteList from "../app/components/athlete/AthleteList";
import {
  athleteNewestAnnualPlans,
  mapAnnualPlansToItems,
} from "../app/resources/simpleFunction";
import { Role } from "../app/utils/enums";
import NewRow from "../app/components/NewRow";
import DashboardGraph from "../app/components/DashboardGraph";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";

const DashboardPage = (): React.ReactElement => {
  const plans = useAppSelector(selectPlan);
  const { currentProfile } = useContext(MainContext);
  const dispatch = useAppDispatch();
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        if (isActive) {
          await dispatch(getAnnualPlans());
        }
      } catch (error: any) {}
    };

    void onScreenOpening();

    return () => {
      isActive = false;
    };
  }, []);

  return (
    <React.Fragment>
      <NewRow height={"90px"} />
      {plans.values && plans.values.length === 0 && (
        <EmptyComponent
          paddingTop="5rem"
          fontSize={"h4"}
          text={i18n.appData.noAnnualPlan}
          icon={
            <ListAlt sx={{ fontSize: "3rem", color: palette.custom.gray }} />
          }
        />
      )}
      {plans.values && plans.values.length > 0 && (
        <Grid container spacing={5} sx={{ minHeight: "86vh" }}>
          <Grid item xs={3}>
            {plans.values && plans.values?.length > 0 && (
              <CustomEventCalendar
                data={
                  currentProfile?.role === Role.Athlete
                    ? mapAnnualPlansToItems(plans.values)
                    : mapAnnualPlansToItems(
                        athleteNewestAnnualPlans(plans.values)
                      )
                }
              />
            )}
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {plans.values && plans.values?.length > 0 && (
              <DashboardGraph
                plans={
                  currentProfile?.role === Role.Athlete
                    ? mapAnnualPlansToItems(plans.values)
                    : mapAnnualPlansToItems(
                        athleteNewestAnnualPlans(plans.values)
                      )
                }
              />
            )}
          </Grid>
          <Grid item xs={4} sx={{ width: "90%" }}>
            <Box sx={{ overflowY: "auto", height: "82vh" }}>
              {plans.values && plans.values?.length > 0 && (
                <AthleteList
                  data={
                    currentProfile?.role === Role.Athlete
                      ? mapAnnualPlansToItems(plans.values)
                      : mapAnnualPlansToItems(
                          athleteNewestAnnualPlans(plans.values)
                        )
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DashboardPage;
