// ** Theme Type Import
import { Theme } from "@mui/material/styles";
import DefaultPalette from "../palette";

const Typography = (theme: Theme) => {
  const palette = DefaultPalette("dark", "custom");
  return {
    // Adaptify login page
    h1: {
      fontStyle: "italic",
      fontWeight: "700",
      fontSize: "72px",
      fontFamily: "Montserrat",
      color: theme.palette.text.primary,
    },
    // tekst login page, forgotPassword
    h2: {
      fontWeight: "400",
      fontFamily: "Montserrat",
      color: theme.palette.text.secondary,
      fontSize: "16px",
    },
    // Login login page
    h3: {
      fontWeight: "700",
      fontFamily: "Montserrat",
      fontSize: "32px",
      color: theme.palette.text.secondary,
    },

    h4: {
      color: theme.palette.text.primary,
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontSize: "16px",
    },
    h5: {
      fontWeight: 700,
      color: theme.palette.info.contrastText,
      fontFamily: "Montserrat",
      fontSize: "20px",
      height: "29px",
    },
    // Adaptify navigationBar
    h6: {
      color: theme.palette.text.primary,
      fontSize: "28px",
      fontFamily: "Montserrat",
      fontWeight: "700",
    },

    subtitle1: {
      color: palette.custom.white,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400",
    },
    subtitle2: {
      letterSpacing: "0.1px",
      fontFamily: "Montserrat",
      color: theme.palette.text.secondary,
    },
    body1: {
      letterSpacing: "0.15px",
      fontFamily: "Montserrat",
      color: theme.palette.text.primary,
    },
     //week line
    body2: {
      fontFamily: "Montserrat",
      fontWeight: 'normal',
      fontSize: '15px',
      color: palette.custom.white,
    },
    button: {
      letterSpacing: "0.3px",
      fontFamily: "Montserrat",
      color: "red",
    },
    //week line
    caption: {
      fontFamily: "Montserrat",
      fontWeight: 'bold',
      fontSize: '15px',
      color: palette.custom.white,
    },
    overline: {
      letterSpacing: "1px",
      color: "red",
    },
  };
};

export default Typography;
