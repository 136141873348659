import React, { useContext, useEffect, useState } from "react";
import { Typography, Container, Box, Grid } from "@mui/material";
import CustomCalendar from "./CustomCalendar";
import { LanguageContext } from "../context/LanguageContext";
import { MainContext } from "../context/MainContext";
import { useAppDispatch } from "../hooks";
import {
  getAnnualPlan,
  resetValueAthleteState,
  resetValueState,
} from "../reducers/plansSlice";
import { Role } from "../utils/enums";
import { valuesForCalendar } from "../resources/simpleFunction";
import CustomEventData from "./CustomEventData";
import DefaultPalette from "../theme/palette";

interface Props {
  data?: any;
}

const CustomEventCalendar = (props: Props): React.ReactElement => {
  const { data } = props;
  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useContext(MainContext);
  const dispatch = useAppDispatch();
  const [calendarData, setCalendarData] = useState<any>([]);
  const palette = DefaultPalette("dark", "custom");

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        if (isActive) {
          if (data && isActive) {
            if (currentProfile.role === Role.Athlete) {
              const idAnnual = data[0]?.annualPlanId;
              let athletePlans: any = await dispatch(getAnnualPlan(idAnnual));
              if (athletePlans.type.includes("fulfilled")) {
                setCalendarData((prev: any) => {
                  const existingIndex = prev.findIndex(
                    (item: any) => item.id === idAnnual
                  );

                  if (existingIndex !== -1) {
                    prev[existingIndex] = {
                      ...prev[existingIndex],
                      ...athletePlans.payload,
                    };
                    return [...prev];
                  } else {
                    return [...prev, { ...athletePlans.payload }];
                  }
                });
              }
            } else {
              for (let i = 0; i < data?.length; i++) {
                const annualPlanId = data[i]?.annualPlanId;
                let athletePlans: any = await dispatch(
                  getAnnualPlan(annualPlanId)
                );
                if (athletePlans.type.includes("fulfilled")) {
                  setCalendarData((prev: any) => {
                    const existingIndex = prev.findIndex(
                      (item: any) => item.id === annualPlanId
                    );

                    if (existingIndex !== -1) {
                      prev[existingIndex] = {
                        ...prev[existingIndex],
                        ...athletePlans.payload,
                      };
                      return [...prev];
                    } else {
                      return [...prev, { ...athletePlans.payload }];
                    }
                  });
                }
              }
            }
            await dispatch(resetValueState());
            await dispatch(resetValueAthleteState());
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    };

    void onScreenOpening();

    return () => {
      isActive = false;
    };
  }, []);

  const boxStyle = {
    minHeight: "86vh",
    backgroundColor: palette.custom.background,
    padding: "10px",
    borderRadius: "8px",
    marginLeft: "8px",
  };

  return (
    <Grid container width={"100%"} sx={boxStyle}>
      <Grid item lg={12}>
        <Typography variant="subtitle1">
          {i18n.appData.eventCalendar}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        {calendarData && (
          <CustomCalendar data={valuesForCalendar(calendarData)} />
        )}
        {calendarData && (
          <CustomEventData data={valuesForCalendar(calendarData)} />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomEventCalendar;
