import React, { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { Role } from "../../utils/enums";
import AthleteListItem from "./AthleteListItem";
import { Typography } from "@mui/material";
import DefaultPalette from "../../theme/palette";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { getAnnualPlan } from "../../reducers/plansSlice";

const AthleteList = (props: any) => {
  const { data } = props;
  const { currentProfile } = useContext(MainContext);
  const palette = DefaultPalette("dark", "custom");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onArrowClick = async (id: any) => {
    await dispatch(getAnnualPlan(id));
    navigate("/annualPlan");
  };

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column", gap: "5px"}}
    >
      <Typography color={palette.custom.white}>
        {currentProfile?.role !== Role.Athlete ? "Athletes" : "My seasons"}
      </Typography>
      {data &&
        data.length > 0 &&
        data?.map((item: any, idx: any) => (
          <AthleteListItem
            key={idx}
            title={item.name}
            subtitle={item.sport}
            subtitle2={`${item.start_date} - ${item.end_date}`}
            onArrowClick={() => onArrowClick(item.annualPlanId)}
          />
        ))}
    </div>
  );
};

export default AthleteList;
