import React, { useContext } from "react";

import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomPasswordField from "./CustomPasswordField";
import CustomTextField from "./CustomTextField";
import CustomDropDownField from "./CustomDropDownField";
import { LanguageContext } from "../context/LanguageContext";
import CustomDateField from "./CustomDateField";
import CustomWeekField from "./CustomWeekField";
import { Typography } from "@mui/material";
import CustomBlueButton from "./CustomBlueButton";
import DefaultPalette from "../theme/palette";
import CustomAccordionItem from "./CustomAccordionItem";
import { useAppSelector } from "../hooks";
import { selectPlan } from "../reducers/plansSlice";

interface Props {
  formFields: any;
  onFormSubmit: any;
  initialValues: any;
  validationSchema?: any;
  disabled: boolean;
  text?: any;
  onDeleteFunction?: any;
  cancelFunction?: any;
}

const CustomForm = (props: Props) => {
  const { i18n } = useContext(LanguageContext);
  const annualPlans: any = useAppSelector(selectPlan);
  const palette = DefaultPalette("dark", "custom");
  const onFormSubmit = async (values: any) => {
    try {
      props.onFormSubmit(values);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validationSchema: props?.validationSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={6}>
        {props.formFields.length !== 0 &&
          props.formFields.map((f: any, ind: any) => {
            return (
              <Grid key={ind} item xs={12}>
                {f.type === "password" && (
                  <CustomPasswordField
                    name={f.name}
                    label={f.label}
                    formik={formik}
                  />
                )}
                {f.type === "title" && (
                  <Typography variant="h2">{f.text}</Typography>
                )}
                {f.type === "text" && (
                  <CustomTextField
                    name={f.name}
                    label={f.label}
                    placeholder={f.placeholder}
                    formik={formik}
                  />
                )}
                {f.type === "select" && (
                  <CustomDropDownField
                    name={f.name}
                    label={f.label}
                    formik={formik}
                    placeholder={f.placeholder}
                    selectValues={f.selectValues}
                  />
                )}
                {(f?.type?.includes("date") || f?.type?.includes("time")) && (
                  <CustomDateField
                    name={f.name}
                    label={f.label}
                    formik={formik}
                    type={f.type}
                    minDate={annualPlans?.valuesWeek[0]?.day}
                    maxDate={
                      annualPlans?.valuesWeek[6]?.day ??
                      annualPlans?.valueSeason?.end_date
                    }
                  />
                )}
                {f.type === "week" && (
                  <CustomWeekField
                    name={f.name}
                    label={f.label}
                    formik={formik}
                  />
                )}
                {f.type === "accordion" && (
                  <CustomAccordionItem
                    icon="plus"
                    name={f.name}
                    text={f.label}
                    placeholder={f.placeholder}
                    formik={formik}
                  />
                )}
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={props.disabled}
            fullWidth
            type="submit"
            sx={{
              backgroundColor: props.disabled ? "grey.500" : "primary.main",
              color: props.disabled ? "grey.300" : "white",
              "&:hover": {
                backgroundColor: props.disabled ? "grey.500" : "primary.dark",
              },
            }}
          >
            {props.text ?? i18n.appData.submit}
          </Button>
          {props?.onDeleteFunction && (
            <CustomBlueButton
              label={i18n.plans.delete}
              onPress={() => props?.onDeleteFunction(formik.values)}
              backgroundColor={"transparent"}
              textColor={palette.error.main}
              textDecoration={"none"}
              fontWeight="normal"
              marginTop="10px"
            />
          )}
          {props?.cancelFunction && (
            <CustomBlueButton
              label={i18n.plans.cancel}
              onPress={props?.cancelFunction}
              backgroundColor={"transparent"}
              textColor={palette.custom.lightGray}
              textDecoration={"underline"}
              fontWeight="normal"
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomForm;
