import React, { useState, useContext } from "react";

import * as yup from "yup";
import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { forgotPassword } from "../../app/reducers/authSlice";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";

const ForgotPasswordForm = (): React.ReactElement => {
  const { i18n } = useContext(LanguageContext);

  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const fields = [
    {
      name: "email",
      label: i18n.auth.lblEmail,
      type: "text",
    },
  ];

  const init = {
    email: "",
  };

  const scheme = yup.object({
    email: yup
      .string()
      .email(i18n.auth.errors.invalidEmailFormat)
      .required(i18n.auth.errors.requiredEmail),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const res = await dispatch(forgotPassword(values));

      if (res.meta.requestStatus === "fulfilled") {
        setSuccess(i18n.auth.success.successForgot)
      } else {
        setError(i18n.auth.errors.notValidCredential);
      }

      setDisabled(false);
    } catch {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          text={i18n.auth.resetPassword}
        />
      )}
      {success !== "" && (
        <Typography variant="subtitle1" color={"success"} paddingTop={2}>
          {success}
        </Typography>
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default ForgotPasswordForm;
