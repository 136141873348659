import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import CustomTextField from "./CustomTextField";
import DefaultPalette from '../theme/palette';

interface Props {
  icon?: string;
  name?: string;
  text: string;
  description?: string;
  placeholder?: string;
  formik?: any;
}

const CustomAccordionItem: React.FC<Props> = ({
  icon,
  name,
  text,
  description,
  placeholder,
  formik,
}) => {
  const [open, setOpen] = useState(false);
  const palette = DefaultPalette("dark", "custom")

  const handleAccordionClick = () => {
    setOpen(!open);
  };

  return (
    <Accordion sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        sx={{ padding: '0px', minHeight: '20px' }}
        expandIcon={icon !== "plus" ? <ExpandMore /> : <Add />}
        onClick={handleAccordionClick}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant={icon !== "plus" ? "body2" : "body2"}>
          {text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ boxShadow: 'none', border: 'none' }}>
        {icon !== "plus" ? (
          <Typography variant="body1" sx={{color: palette.custom.white}}>{description}</Typography>
        ) : (
          <CustomTextField
            name={name}
            placeholder={placeholder}
            formik={formik}
            multiline={true}
            numberOfRows={3}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordionItem;
