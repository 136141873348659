import { Login, LoginData } from "../../models/Login";
import API_AUTH from "../api/api_auth";
import API from "../api/api";

export class AuthService {
  async login(payload: Login): Promise<LoginData> {
    const data = await API_AUTH.post("/auth/jwt/create/", payload);

    return new LoginData(data.data);
  }

  async authUser() {
    const data = await API_AUTH.get("/auth/users/");
    return data;
  }

  async getUser() {
    const data = await API.get("/auth/users/me");
    return data;
  }

  async forgotPassword(values: any): Promise<any> {
    const data = await API.post('/auth/users/reset_password/', values);

    return data;
  }

  async resetPasswordConfirm(uid: any, token: any, new_password: any) {
    const data = await API.post(`/auth/users/reset_password_confirm/`, {
      uid: uid,
      token: token,
      new_password: new_password,
    });

    return data;
  }

  async deleteUser(values: any) {
    const current_password = values.current_password;
    const data = await API.delete(`/auth/users/${values.id}`, {
      data: { current_password },
    });
    return data;
  }

  async changePassword(values: any) {
    const data = await API.post(`/auth/users/set_password/`, values);
    return data;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }
}

export const authService = new AuthService();
