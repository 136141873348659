import React, { useEffect, ReactNode } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import ThemeComponent from "./app/theme/ThemeComponent";
import { SettingsConsumer } from "./app/context/settingsContext";
import BlankLayout from "./app/layouts/BlankLayout";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/LoginPage";
import { isTokenExpired } from "./app/api/api";
import UserLayout from "./app/layouts/UserLayout";
import MainStore from "./app/context/MainStore";
import API_AUTH from "./app/api/api_auth";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import DashboardPage from "./pages/DashboardPage";
import AnnualPlanPage from "./pages/AnnualPlanPage";
import CurrentWeekPage from "./pages/CurrentWeekPage";
import WeeklyPlanPage from "./pages/WeeklyPlanPage";
import { Provider } from "react-redux";
import { store } from "./app/store/store";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfConditions from "./pages/TermsOfConditions";
import SettingsPage from "./pages/SettingsPage";
import NewPasswordPage from './pages/NewPasswordPage';

function App() {
  useEffect(() => {
    API_AUTH.get("/keep_live/");
    const interval = setInterval(() => {
      API_AUTH.get("/keep_live/");
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const refreshToken = localStorage.getItem("refreshToken");
  const condition = refreshToken && !isTokenExpired(refreshToken);

  const getBlankLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;
  const getUserLayout = (page: ReactNode) => <UserLayout>{page}</UserLayout>;

  return (
    <Router>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <Provider store={store}>
              <MainStore>
                <ThemeComponent settings={settings}>
                  <Routes>
                    {condition && refreshToken && (
                      <Route
                        path="/"
                        element={getUserLayout(<DashboardPage />)}
                      />
                    )}
                    {condition && refreshToken && (
                      <Route
                        path="/annualPlan"
                        element={getUserLayout(<AnnualPlanPage />)}
                      />
                    )}
                    {condition && refreshToken && (
                      <Route
                        path="/weeklyPlan"
                        element={getUserLayout(<WeeklyPlanPage />)}
                      />
                    )}
                    {condition && refreshToken && (
                      <Route
                        path="/currentWeek"
                        element={getUserLayout(<CurrentWeekPage />)}
                      />
                    )}
                    <Route
                      path="/"
                      element={
                        condition ? (
                          <Navigate to="*" />
                        ) : (
                          getBlankLayout(<LoginPage />)
                        )
                      }
                    />
                    <Route
                      path="/register"
                      element={
                        condition ? (
                          <Navigate to="*" />
                        ) : (
                          getBlankLayout(<SignUpPage />)
                        )
                      }
                    />
                    <Route
                      path="/password"
                      element={
                        condition ? (
                          <Navigate to="*" />
                        ) : (
                          getBlankLayout(<ForgotPasswordPage />)
                        )
                      }
                    />
                                <Route
              path="/password/reset/confirm/:uid/:token"
              element={
                condition ? (
                  <Navigate to="*" />
                ) : (
                  getBlankLayout(<NewPasswordPage />)
                )
              }
            />
                    <Route
                      path="/privacyPolicy"
                      element={
                        condition && refreshToken ? (
                          <Navigate to="*" />
                        ) : (
                          getBlankLayout(
                            <PrivacyPolicyPage isLoggedIn={false} />
                          )
                        )
                      }
                    />
                    <Route
                      path="/termsOfConditions"
                      element={
                        condition && refreshToken ? (
                          <Navigate to="*" />
                        ) : (
                          getBlankLayout(
                            <TermsOfConditions isLoggedIn={false} />
                          )
                        )
                      }
                    />
                    <Route
                      path="*"
                      element={
                        condition
                          ? getUserLayout(<ErrorPage />)
                          : getBlankLayout(<ErrorPage />)
                      }
                    />
                  </Routes>
                </ThemeComponent>
              </MainStore>
            </Provider>
          );
        }}
      </SettingsConsumer>
    </Router>
  );
}

export default App;
