import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteCompetition,
  deleteTraining,
  selectPlan,
} from "../../reducers/plansSlice";
import WeeklyCompetitionCard from "./WeeklyCompetitionCard";
import WeeklyTrainingCard from "./WeeklyTrainingCard";

interface Props {
  closeModal: any;
  setReload: any;
}
const WeeklyDetailsData = (props: Props): React.ReactElement => {
  const { closeModal, setReload } = props;
  const annualPlans = useAppSelector(selectPlan);
  const dispatch = useAppDispatch();
  const data = annualPlans.valueWeek;

  const isCompetition = data && data?.date_of_competition ? true : false;
  // const currentDate = new Date(Date.UTC(
  //   new Date().getUTCFullYear(),
  //   new Date().getUTCMonth(),
  //   new Date().getUTCDate(),
  //   new Date().getUTCHours(),
  //   new Date().getUTCMinutes(),
  //   new Date().getUTCSeconds(),
  //   new Date().getUTCMilliseconds()
  // )).getTime();

  const currentUTC = Date.now();

  const timezoneOffsetMinutes = new Date().getTimezoneOffset();

  const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60 * 1000;

  const currentDate = currentUTC - timezoneOffsetMilliseconds;

  const objectDate =
    data && data?.day_time
      ? Date.parse(data?.day_time)
      : Date.parse(data?.date_of_competition);

  const handleDeleteCompetition = async () => {
    try {
      const res = await dispatch(deleteCompetition(annualPlans.valueWeek?.id));
      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
      }
    } catch (error: any) {}
  };

  const handleDeleteTraining = async () => {
    try {
      const res = await dispatch(deleteTraining(annualPlans.valueWeek?.id));
      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
      }
    } catch (error: any) {}
  };

  return (
    <div>
      {data && isCompetition ? (
        <WeeklyCompetitionCard
          data={annualPlans.valueWeek && data}
          isPast={currentDate > objectDate}
          handleDeleteCompetition={handleDeleteCompetition}
          closeModal={closeModal}
          setReload={setReload}
        />
      ) : (
        <WeeklyTrainingCard
          data={annualPlans.valueWeek && data}
          isPast={currentDate > objectDate}
          handleDeleteTraining={handleDeleteTraining}
          closeModal={closeModal}
          setReload={setReload}
        />
      )}
    </div>
  );
};

export default WeeklyDetailsData;
