import React, { useContext } from "react";
import DefaultPalette from "../../theme/palette";
import "./CustomWeeklyBody.css";
import { LanguageContext } from "../../context/LanguageContext";
import { Typography } from "@mui/material";
import { isDateBetweenUtc } from "../../resources/simpleFunction";
import { importanceDisplayObject } from "../../utils/enums";

interface Props {
  data: any;
  mc: any;
  onCycleClick: any
}

const CustomMicrocycle: React.FC<Props> = ({ data, mc, onCycleClick }: Props) => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");
  const cycle = [].concat(...data.map((d: any) => d.weeks));

  const styles: any = {
    typo: {
      textAlign: "center",
    },
    table: {
      tableLayout: "fixed",
      width: "100%",
      border: `1px solid ${palette.custom.gray}`,
    },
    cell: {
      border: `1px solid ${palette.custom.gray}`,
      borderCollapse: "collapse",
    },
  };

    return (
    <table style={styles.table}>
      <tbody style={styles.cell}>
        <tr>
          <td colSpan={5} style={{...styles.cell, backgroundColor: palette.custom.darkBlue}}>
            <Typography variant="body2" sx={styles.typo}>
              {i18n.plans.microcycle}
            </Typography>
          </td>
          {cycle &&
            cycle.length > 0 &&
            cycle.map((v: any, idx: any) => {
              const isCurrent = mc !== undefined ? mc===v.number : isDateBetweenUtc(
                v?.week_start_date.split("T")[0],
                v?.week_end_date.split("T")[0]
              );
              return (
                <td
                  key={idx}
                  style={{
                    ...styles.cell,
                    backgroundColor: isCurrent && palette.custom.orange,
                  }}
                >
                  <Typography variant="body2" sx={{...styles.typo, cursor: "pointer"}} onClick={() => {onCycleClick(v)}}>
                    {v.number}
                  </Typography>
                </td>
              );
            })}
        </tr>
        <tr>
          <td colSpan={5} style={{...styles.cell, backgroundColor: palette.custom.darkBlue}}>
            <Typography variant="body2" sx={styles.typo}>
              {i18n.plans.importance}
            </Typography>
          </td>
          {cycle &&
            cycle.length > 0 &&
            cycle.map((v: any, idx: any) => {
              const isCompetition = v?.competition !== undefined;
              return (
                <td key={idx} style={styles.cell}>
                  <Typography variant="body2" sx={styles.typo}>
                    {isCompetition &&
                      importanceDisplayObject[v?.competition?.importance]}
                  </Typography>
                </td>
              );
            })}
        </tr>
      </tbody>
    </table>
  );
};

export default CustomMicrocycle;
