import React from "react";
import { useAppSelector } from "../hooks";
import { selectPlan } from "../reducers/plansSlice";
import { Typography } from "@mui/material";
import DefaultPalette from "../theme/palette";
import { format, utcToZonedTime } from "date-fns-tz";

interface Props {
  value: any;
  color: string;
}

const CustomBar = (props: Props): React.ReactElement => {
  const { value, color } = props;
  const palette = DefaultPalette("dark", "custom");
  const annualPlans = useAppSelector(selectPlan);

  const maxObject = annualPlans.weekSessionValues?.reduce(
    (max: any, current: any) => {
      return current.load > max.load ? current : max;
    },
    annualPlans.weekSessionValues[0]
  );

  const date = format(utcToZonedTime(value?.training_time, "Etc/UTC"), "EEE", {
    timeZone: "Etc/UTC",
  })

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        style={{
          fontSize: "14px",
          color: palette.custom.orange,
          marginTop: 12,
          marginBottom: 5,
          textAlign: "center",
        }}
      >
        {value?.load}
      </Typography>
      <div
        style={{
          height: (value?.load / maxObject.load) * 230,
          backgroundColor: color,
          width: "40%",
          flexDirection: "column-reverse",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      />
      <Typography
        style={{
          fontSize: "14px",
          color: palette.custom.white,
          marginTop: 12,
          marginBottom: 5,
          textAlign: "center",
          transform: "rotate(-80deg)",
        }}
      >
        {date}
      </Typography>
    </div>
  );
};

export default CustomBar;
