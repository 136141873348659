import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DefaultPalette from "../theme/palette";
import { enGB } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";

interface Props {
  data?: any[];
}

class CustomString extends String {
  charAt(_: number): any {
    return {
      toUpperCase: () => this.valueOf(),
    };
  }
}
const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const customWeekDays = weekDays.map((day) => new CustomString(day) as string);

export class DateAdapter extends AdapterDateFns {
  getWeekdays = (): string[] => customWeekDays;
}

function CustomCalendar({ data }: Props): React.ReactElement {
  const uniqueDates = React.useMemo(() => {
    if (data && data.length > 0) {
      const dateSet = new Set();
      data.forEach((item) => {
        dateSet.add(item?.competition?.date_of_competition);
      });
      return Array.from(dateSet);
    }
    return [];
  }, [data]);

  const palette = DefaultPalette("dark", "custom");

  const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: palette.custom.blue,
      color: palette.custom.white,
    },
  }));

  const ServerDay = ({ day, outsideCurrentMonth, ...other }: any) => {
    const isSelected =
      !outsideCurrentMonth && uniqueDates.includes(format(day, "yyyy-MM-dd"));

    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };

  const CustomServerDay = (props: any) => {
    return <ServerDay {...props} />;
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={enGB}>
      <StaticDatePicker
        readOnly
        slots={{
          day: CustomServerDay,
        }}
        sx={{
          backgroundColor: palette.custom.gray,
          borderRadius: "5px",
          minWidth: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          "& .MuiButtonBase-root": {
            color: palette.custom.white,
          },

          "& .MuiPickersCalendarHeader-root, & .MuiPickersCalendarHeader-label, & .MuiPickersCalendarHeader-switchViewIcon, & .MuiPickersLayout-root, & .MuiDialogActions-root, & .MuiPickersLayout-toolbar":
            {
              display: "none",
            },
          "& .MuiDayCalendar-weekDayLabel": {
            backgroundColor: palette.custom.blue,
            color: palette.custom.white,
            fontSize: "1rem",
            margin: "0px",
            padding: "0px",
            textTransform: "none",
            width: "100%",
          },
          "& .MuiMenu-root": {
            backgroundColor: palette.custom.blue,
          },
          "& .MuiPickersDay-root": {
            borderRadius: "20%",
            fontSize: "1rem",
            cursor: "default",
            width: "20%",
          },
          "& .MuiDayCalendar-weekContainer": {
            justifyContent: "space-around",
          },
          "& .MuiPickersLayout-contentWrapper": {
            width: "100%",
          },
          "& .MuiDateCalendar-root": {
            width: "100%",
          },
          "& .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition":
            {
              height: "34vh",
            },
        }}
      />
    </LocalizationProvider>
  );
}

export default React.memo(CustomCalendar);
