import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import { postCompetitionReport } from "../../app/reducers/plansSlice";
import { MainContext } from "../../app/context/MainContext";

interface Props {
  competition?: any;
  closeModal?: any;
  setReload?: any;
}

const CompetitionReportForm = (props: Props): React.ReactElement => {
  const { competition, closeModal, setReload } = props;
  const { currentProfile } = useContext(MainContext);
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);

  const fields = [
    {
      text: i18n.plans.reportDetails,
      type: "title",
    },
    {
      name: "description",
      label: i18n.plans.reportDetails,
      type: "text",
    },
    {
      name: "physical_preparation",
      label: i18n.plans.physicalPreparation,
      placeholder: i18n.plans.evalAnal,
      type: "accordion",
    },
    {
      name: "psychology",
      label: i18n.plans.psychology,
      placeholder: i18n.plans.evalAnal,
      type: "accordion",
    },
    {
      name: "technique",
      label: i18n.plans.technique,
      placeholder: i18n.plans.evalAnal,
      type: "accordion",
    },
    {
      name: "tactic",
      label: i18n.plans.tactic,
      placeholder: i18n.plans.evalAnal,
      type: "accordion",
    },
  ];

  const init = {
    description: "",
    physical_preparation: "",
    psychology: "",
    technique: "",
    tactic: "",
  };

  const scheme = yup.object({
    description: yup.string().required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const formData = {
        ...values,
        competition: competition.id,
        profile: currentProfile.id,
      };
      let res = await dispatch(postCompetitionReport(formData));

      if (res?.meta.requestStatus === "fulfilled") {
        setReload((v: any) => !v)
        closeModal();
      } else {
        setError("Error!");
      }

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default CompetitionReportForm;
