import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DefaultPalette from "../../theme/palette";
import arrowRight from "../../resources/svg/arrowRightSvg.svg";


const AthleteListItem = (props: any) => {
  const { title, subtitle, subtitle2, onArrowClick } = props;
  const palette = DefaultPalette("dark", "custom");

  return (
    <Card
      sx={{
        height: "80px",
        borderRadius: "4px",
        marginRight: "10px",
        backgroundColor: palette.custom.background,
        border: `1px solid ${palette.custom.gray}`,
      }}
    >
      <CardContent sx={{ paddingTop: "5px", paddingLeft: "5px" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton sx={{ marginTop: "-1px" }}>
              <Avatar sx={{ width: "58px", height: "58px" }} />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column" justifyContent="space-around">
              <Typography
                color={palette.custom.white}
                fontWeight={"700"}
                fontSize={"18px"}
              >
                {title}
              </Typography>
              <Typography color={palette.custom.gray} fontSize={"12px"}>
                {subtitle}
              </Typography>
              <Typography color={palette.custom.orange} fontSize={"12px"}>
                {subtitle2}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={onArrowClick}>
              <img src={arrowRight} alt="Arrow Right Icon" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AthleteListItem;
