import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface Props {
  formik?: any;
  type?: string;
  name: string;
  label?: string;
  width?: string;
  children?: any;
}

const CustomTextField = (props: Props | any): React.ReactElement => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TextField
      sx={{ width: props?.width ?? "100%" }}
      id={props.name}
      label={props.label}
      type={"week"}
      name={props.name}
      variant="outlined"
      value={props.formik?.values[props.name]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.name] &&
        Boolean(props.formik.errors[props.name])
      }
      helperText={
        props.formik.touched[props.name] && props.formik.errors[props.name]
      }
    >{props.children}</TextField>
    </LocalizationProvider>
  );
};

export default CustomTextField;
