import React from "react";

interface Props {
  height: any;
}
const NewRow = (props: Props): React.ReactElement => {
  return <div style={{ height: props.height }} />;
};

export default NewRow;
