import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import DefaultPalette from "../theme/palette";
import { enGB } from "date-fns/locale";

interface Props {
  formik?: any;
  type?: string;
  name: string;
  label?: string;
  width?: string;
  maxDate?: any;
  minDate?: any;
}

const CustomDateField = (props: Props): React.ReactElement => {
  const { formik, name, type, label, width, maxDate, minDate } = props;
  const field = formik.getFieldProps(name);

  const today = new Date();
  const year = today.getFullYear();
  const futureDate = new Date(`${year + 50}-05-05`);

  const minimum =
    minDate && today.getTime() < new Date(minDate).getTime()
      ? new Date(minDate)
      : today;
  const maximum =
    maxDate && today.getTime() <= new Date(maxDate).getTime()
      ? new Date(maxDate)
      : futureDate;

  const initialDate = field?.value
    ? new Date(field.value)
    : minDate
    ? new Date(minDate)
    : new Date();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const palette = DefaultPalette("dark", "custom");

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleDateChange = (value: Date | null) => {
    formik.setFieldValue(name, value);
    handleCalendarClose();
  };

  const style = {
    input: { color: palette.custom.lightGray },
    outline: { color: palette.custom.background },
    background: palette.custom.background,
    borderRadius: "6px",
    borderColor: palette.custom.background,
    width: "100%",
    "& label.Mui-focused": {
      color: palette.custom.lightGray,
    },
    "& .MuiSvgIcon-root": {
      color: palette.custom.lightGray,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: palette.custom.lightGray,
      },
      "&:hover fieldset": {
        borderColor: palette.custom.lightGray,
      },
      "&.Mui-focused fieldset": {
        borderColor: palette.custom.lightGray,
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      {type === "date" && (
        <DatePicker
          minDate={minimum}
          maxDate={maximum}
          value={initialDate || null}
          label={label}
          onChange={handleDateChange}
          sx={style}
        />
      )}
      {type === "time" && (
        <TimePicker
          value={initialDate ?? new Date()}
          onChange={handleDateChange}
          ampm={false}
          label={label}
          sx={{
            ...style,
            input: {
              ...style.input,
              overflow: "hidden",
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default CustomDateField;
