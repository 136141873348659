import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import Typography from "@mui/material/Typography";
import { authService } from "../../app/services/authService";
import { LanguageContext } from "../../app/context/LanguageContext";
import CustomForm from "../../app/components/CustomForm";

const initialValues = {
  new_password: "",
  re_new_password: "",
};

const NewPasswordForm = (): React.ReactElement => {
  const { uid, token } = useParams();
  const { i18n } = useContext(LanguageContext);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState("");

  const resetPassword = async (values: any) => {
    try {
      setDisabled(true);
      const new_password = values.new_password;
      const re_new_password = values.re_new_password;

      if (new_password === re_new_password) {
        const res = (await authService.resetPasswordConfirm(
          uid,
          token,
          new_password
        )) as any;

        if (res.status === 204) {
          setSuccess(i18n.auth.success.successChangePass);
          setDisabled(false);
        }
      }
    } catch (err: any) {
      setDisabled(false);
      if (err.response) {
        const values: any = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        if (
          values[0][0] ===
            "This password is too short. It must contain at least 8 characters." &&
          keys[0] === "new_password"
        ) {
          setError(i18n.auth.errors.tooShort);
          return;
        } else if (
          values[0][0] === "This field may not be blank." &&
          keys[0] === "new_password"
        ) {
          setError(i18n.auth.errors.allFields);
          return;
        } else if (
          values[0][0] === "This password is too common." &&
          keys[0] === "new_password"
        ) {
          setError(i18n.auth.errors.tooCommon);
          return;
        } else if (
          values[0][0] === "The password is too similar to the username." &&
          keys[0] === "new_password"
        ) {
          setError(i18n.auth.errors.similarEmail);
          return;
        }
        setError(JSON.stringify(err.response.data));
      }
    }
  };

  const fields = [
    {
      name: "new_password",
      label: i18n.auth.lblNewPassword,
      type: "password",
    },
    {
      name: "re_new_password",
      label: i18n.auth.lblRePassword,
      type: "password",
    },
  ];

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .min(8, i18n.appData.passValidationMin)
      .max(40, i18n.appData.passValidationMax)
      .required(i18n.appData.requiredField),
    re_new_password: yup
      .string()
      .required(i18n.appData.requiredField)
      .oneOf([yup.ref("new_password"), ""], i18n.appData.passNotSame)
      
  });

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onFormSubmit={resetPassword}
          disabled={disabled}
        />
      )}
      {success !== "" && (
        <Typography variant="subtitle1" color={"success"} paddingTop={2}>
          {success}
        </Typography>
      )}

      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default NewPasswordForm;
